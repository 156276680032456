import React, { useContext, useEffect, useState } from 'react';
import {Accordion, AccordionCollapse, AccordionContext, Card, useAccordionToggle} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';
import config, { ChannelConfigSettings } from '../../../../config/channels';
import { TSite } from '../../../../contexts/site/site-context';
import EatInModal from './EatInModal';
import { darken, rgba } from 'polished';
import mixpanel from "mixpanel-browser";
import {CTA} from "../../../general/Button";


type SiteCardProps = {
    site: TSite;
    channel: ChannelConfigSettings;
};

const SiteCard = ({ site, channel }: SiteCardProps) => {
    const navigate = useNavigate();
    let openingTimes = '';
    let openingHours: any[] = [];
    const isDineIn = channel.name === config.dineIn.name;

    const [eatInModalShow, setEatInModalShow] = useState(false);
    const [spinnerShow, setSpinnerShow] = useState(false);
    const [link, setLink] = useState(`/${channel.handle}/${site.handle}`);
    const [tableNumber, setTableNumber] = useState('0');

    let open = false;



    switch (channel.name) {
        case config.collection.name:
            open = site.channels.collection?.is_open ?? false;
            openingTimes = site.channels.collection?.opening_times ?? '';
            openingHours = site.channels.collection?.opening_hours ?? [];
            break;

        case config.delivery.name:
            open = site.channels.delivery?.is_open ?? false;
            openingTimes = '';
            openingHours = site.channels.delivery?.opening_hours ?? [];
            break;

        case config.dineIn.name:
            open = site.channels.dine_in?.is_open ?? false;
            openingTimes = site.channels.dine_in?.opening_times ?? '';
            openingHours = site.channels.collection?.opening_hours ?? [];
            break;
    }

    const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (open) {
            setSpinnerShow(true);
            if (isDineIn) {
                setEatInModalShow(true);
            } else {
                navigate(link);
            }
        } else {
            setSpinnerShow(true);
            navigate(`${link}?viewOnly=1`);
        }
    }

    useEffect(() => {
        if (isDineIn) {
            setLink(`/${channel.handle}/${site.handle}/table`);
        }
    }, [channel]);

    function CustomToggle({ children, eventKey, callback }: any) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

        const isCurrentEventKey = currentEventKey === eventKey;

        const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            decoratedOnClick(e);
        }

        return (
            <button
                type="button"
                className={`toggler ${isCurrentEventKey ? 'show' : ''}`}
                onClick={handleClick}
            >
                {children}
                <i className={"feather feather-chevron-down feather-18 transition duration-400"} />
            </button>
        );
    }

    const renderOpeningDay = () => {
        return (
            <>
                <div>
                    <i className="feather feather-clock feather-20 brand-color-filter"/>
                </div>
                <div>
                    {
                        openingHours.map((hour) => (
                            <OpeningDay key={'opening_' + hour.day}>
                                <span className="day">{hour.day}</span>
                                <span className="hours">{hour.time}</span>
                            </OpeningDay>
                        ))
                    }
                </div>
            </>
        )
    }

    const CtaStr = () => {
        if ( !open ) {
            return 'View menu';
        }

        if ( channel.handle === 'collection' ) {
            return 'Start order';
        }
        return 'Order for ' + channel.displayName.toLowerCase();
    }

    const CtaLink = () => { return open ? link : link + '?viewOnly=1'; }
    const CtaMixpanelTrack = () => { return open ? 'view menu clicked' : 'view menu clicked (closed)'; }

    const handleCtaClick = () => {
        setSpinnerShow(true);
        mixpanel.track(CtaMixpanelTrack());
        navigate(CtaLink());
    };


    return (
        <StyledCard
            open={open}
            className={`site-card ${open ? 'openRestaurant' : 'closedRestaurant'}`}
        >
            {/*{isDineIn ? (
                <EatInModal
                    show={eatInModalShow}
                    site={site}
                    onTableSelected={(tableNo: string) => {
                        setTableNumber(tableNo);
                        setEatInModalShow(false);
                        navigate(`/${channel.handle}/${site.handle}?table=${tableNo}`);
                    }}
                    onHide={() => {
                        setEatInModalShow(false);
                    }}
                />
            ) : null}*/}
            <div className="card__main" tabIndex={1}
                onClick={handleCardClick}
            >

                <Card.Body>
                    <Card.Title>
                        <h3>{site.name}</h3>
                        {site.distance ? <span>{Math.round(site.distance * 10) / 10} miles</span> : null}
                    </Card.Title>
                    <Card.Text as="div">
                        <div className="location">
                            <span>{site.address_1}, {site.address_city}</span>
                        </div>
                        {open ? (
                            <div className="openingTimes">
                                <span>{openingTimes}</span>
                            </div>
                        ) : (
                            <div className="notAcceptingOrders">Not currently accepting orders</div>
                        )}
                    </Card.Text>
                    <StyledAccordion defaultActiveKey="-1">
                        {/* <AccordionToggle eventKey="0">
                    {t`View`} {bItems.length} {t`items in basket`}
                </AccordionToggle> */}

                        <CustomToggle eventKey="0" >
                            <span className="on-closed" onClick={()=> mixpanel.track('View location info')}>More info</span>
                            <span className="on-open">Less info</span>
                            {/* <span className="on-open">Close</span> */}
                        </CustomToggle>
                        <AccordionCollapse eventKey="0">
                            <>
                                <div className="opening-hours-block">
                                    {openingHours.length > 0 ? renderOpeningDay() : null}
                                </div>

                                <div>
                                    <a href={`tel:${site.phone}`} className="one-liner">
                                        <i className="feather feather-phone feather-20 brand-color-filter"/>
                                        <span>{ site.phone }</span>
                                    </a>
                                </div>

                                <div>
                                    <a href={`https://www.google.com/maps/@${site.lat},${site.lng},17z`} target='_blank' className="one-liner" rel="noreferrer">
                                        <i className="feather feather-map feather-20 brand-color-filter"/>
                                        <span>Get directions</span>
                                    </a>
                                </div>
                            </>
                        </AccordionCollapse>
                    </StyledAccordion>
                </Card.Body>
                <Card.Footer>
                    {open ? (<CTAButton className={`card__btn`} onClick={handleCtaClick}>
                        <span>{ CtaStr() }</span>
                        <svg
                            width={20} height={20} fill="none"
                            xmlns="http://www.w3.org/2000/svg" >
                            <path
                                d="M3 9.5h12.5m0 0L10 4m5.5 5.5L10 15"
                                stroke="currentColor" strokeWidth={2} />
                            </svg>
                    </CTAButton>)
                        : (<button className={`card__link text-ui-font`} onClick={handleCtaClick}>
                            <span>{ CtaStr() }</span>
                        </button>) }
                </Card.Footer>
            </div>

        </StyledCard>
    );
};

const CTAButton = styled(CTA)`
    display: inline-flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: var(--text_ui_weight);
    font-family: var(--text_ui_font);
    text-transform: var(--text_ui_case);
    color: var(--brand);
    background: transparent;
    font-size: 18px;
    line-height: 18px;
    padding: 8px 20px;
    border-radius: 4px;
    height: 40px;
    border: 1px solid var(--brand);
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
    @media (min-width: 768px) {
        width: auto;
    }
`;

const StyledAccordion = styled(Accordion)`
    .collapse {
        padding-bottom: 8px;
    }
    .toggler {
        display: flex;
        align-items: center;
        gap: 6px;
        border: 0;
        background-color: transparent;
        padding: 8px 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--text_display_h3_color);
        i {
            transition: transform 0.2s linear;
        }
        .on-open { display: none };
        &:hover { text-decoration: none }
        &.show {
            .on-open { display: block }
            .on-closed { display: none }
            i {
                transform: rotate(180deg);
            }
        }
        transition: background-color 0.2s ease-in-out;
        &:not(.show) {
            // &:hover {
            //     transition: background-color 0.1s ease-in-out;
            //     background-color: ${(props) => rgba(props.theme.v3.ui.other.link, 0.05)};
            // }
        }
    }

    .opening-hours-block {
        padding: 8px 0 16px 0;
        display: flex;
        gap: 16px;
        &__icon{

        }
    }

    .one-liner {
        &:hover { text-decoration: none !important; }
        
        & > i { margin-right: 16px; }
        
        color: var(--brand);
        display: flex;
        align-items: center;
        padding: 12px 0;
        font-weight: 500;
        border-color: var(--border-grey);
        transition: color 0.2s ease-in-out;
        &:hover i {
            text-decoration: none !important;
            color: ${(props) => darken(0.05, props.theme.v3.ui.other.link)};
        }
        &:hover span {
            text-decoration: underline;
        }
    }
`;

const OpeningDay = styled.div`
    display: flex;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
    color: var(--text-black);
    font-weight: 500;

    &:first-child { margin-top: 2px;}
    & > span:first-child { margin-right: 16px; }

    .icon-open { color: var(--text-grey); font-size: 16px; font-weight: 500; }
    .icon-closed { color: var(--invalid); font-size: 16px; font-weight: 500; }
    .hours { flex-grow: 1; }
    .day {
        min-width: 120px;
        letter-spacing: -0.02em;

    }
    @media (max-width: 420px) {
        font-size: 14px;
        line-height: 16px;
        span {
            letter-spacing: -0.02em;
        }
        .day {
            min-width: 76px;
        }
    }
`;


const StyledCard = styled(Card)<{ open: boolean }>`
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.07) !important;
    &:hover {
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 7%) !important;
    }
    
    .card__link {
        display: inline-flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        color: var(--brand);
        background: transparent;
        font-size: 18px;
        line-height: 18px; 
        padding: 4px 0;
        border: 0;
        font-weight: var(--text_ui_weight);
        font-family: var(--text_ui_font);
        border-bottom: 1px solid var(--brand);
        text-transform: var(--text_ui_case);
        border-radius: 0;
        height: auto;
    }

    .card__main {
        transition: background-color 0.15s ease-in-out; 
        &:hover {
            // background-color: ${(props) => rgba(props.theme.v3.ui.other.link, 0.05)};
            ${CTAButton} {
                color: white;
                background-color: var(--brand);
            }
        }
    }

    &:first-child { margin-top: 20px; }
    margin-bottom: 20px;

    transition: box-shadow .15s ease-in-out;

    .card-title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        gap: 8px;
        flex-wrap: wrap;
        h3 {
            flex-grow: 1;
            margin-bottom: 0;
            line-height: 24px;
        }
        span {
            color: var(--text-grey);
            font-size: 15px;
        }
    }
    .card-body {
        padding-bottom: 8px;
        @media screen and (min-width: 768px){
                padding: 20px 20px 0 20px;

        }
        font-size: 15px;


        .location {
            font-size: 16px;
            color: var(--text-grey);
            margin-bottom: 8px;
            font-weight: 500;
            line-height: 20px;
        }

        .location,
        .openingTimes { display: flex; align-items: start; }

        .location i,
        .openingTimes i { margin-right: 8px; line-height: 24px; font-size: 16px; }

        .openingTimes,
        .notAcceptingOrders {
            font-size: 16px;
            color: var(--text-black)
            line-height: 20px;
            font-weight: 500;
            @media screen and (min-width: 1441px) {
                margin-bottom: 8px;
            }
        }
        .notAcceptingOrders {
            font-weight: 600;
        }
    }

    .card-footer {
        padding: 0 20px 20px 20px;
        background: transparent;
        border: none;
        display: flex;
        justify-content: flex-end;

        .btn {
            @media screen and (min-width: 768px) {
                max-width: 260px;
            }
        }
    }

`;

export default SiteCard;
