import { rgba } from 'polished';
import React from 'react';
import {Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../../../contexts/basket/useBasketContext';
import useOperatorContext from '../../../../../contexts/operator/useOperatorContext';
import useSiteContext from '../../../../../contexts/site/useSiteContext';
import { Item } from '../../../../../contexts/site/site-context';
import { money } from '../../../../../services/format';
import {Preferences} from '../../MenuPage';
import mixpanel from "mixpanel-browser";
import {trackEvent} from "../../../../../services/api/operator";

type MenuItemProps = {
    item: Item;
    showItemModal: (item: Item, edit: boolean) => void;
    allowItemImages: boolean;
    preferences: Preferences;
    variant?: string;
};

const MenuItem = ({ item, showItemModal, allowItemImages, preferences, variant }: MenuItemProps) => {
    const basketContext = useBasketContext();
    const operatorContext = useOperatorContext();
    const siteContext = useSiteContext();

    const basketItemCount = () => {
        let count = 0;
        basketContext.basketItems
            .filter((i) => item.uid === i.uid)
            .forEach((i) => count += (i.quantity ?? 0));
        return count;
    };

    const renderTitleBadges = () => {
        let badges = [];
        if (item.is_new) {
            badges.push(
                <Badge key={`tfbg-${item.uid}-new`} className="badge--title badge-style">
                    <div className="badge--title__icon">
                        <i className="feather feather-star color-filter-badge-icon" />
                    </div>
                    {t`New`}
                </Badge>,
            );
        }

        if (item.is_hidden) {

            badges = [];
            badges.push(
                <Badge key={`tfbg-${item.uid}-unavailable`} className="badge--title unavailable-item-text badge-style">
                    {t`Currently unavailable`}
                </Badge>,
            );
        }

        return badges.map((badge) => badge);
    };

    const renderDietBadges = () => {
        let badges = [];
        if (item.is_vegan) {
            badges.push(
                <OverlayTrigger
                    key={'veganPlacement'}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-veganPlacement`}>
                            Suitable for Vegans
                        </Tooltip>
                    }
                >
                    <Badge className="badge-vg" key={`tfbg-${item.uid}-vegan`} title="Vegan">
                        V
                    </Badge>
                </OverlayTrigger>

            );
        }

        if (item.is_halal) {
            badges.push(
                <OverlayTrigger
                    key={'halalPlacement'}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-halalPlacement`}>
                            Halal
                        </Tooltip>
                    }
                >
                    <Badge className="badge-h" key={`tfbg-${item.uid}-halal`} title="Halal">
                        H
                    </Badge>
                </OverlayTrigger>
            );
        }

        // if (item.is_vegetarian) {
        //     badges.push(
        //         <Badge className="badge-v" key={`tfbg-${item.uid}-vegetarian`}>
        //             Vegetarian
        //         </Badge>,
        //     );
        // }

        if (item.is_gluten_free) {
            badges.push(
                <OverlayTrigger
                    key={'gfPlacement'}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-gfPlacement`}>
                            Gluten Free
                        </Tooltip>
                    }
                >
                    <Badge className="badge-gf" key={`tfbg-${item.uid}-gf`} title="Gluten Free">
                        GF
                    </Badge>
                </OverlayTrigger>
            );
        }

        // if (item.allergens) {
        //     badges.push(<Badge key={`tfbg-${item.uid}-allergens`} variant="secondary">Allergens</Badge>)
        // }

        if (badges.length > 0) {
            return <>{badges.map((badge) => badge)}</>;
        }
        return null;
    };

    const renderDetails = () => {
        if ((item.description && styleVariant !== 'portrait') || item.calories !== '' || renderDietBadges() ) {
            return (
                <div className="menu-item__details">
                    {item.description ? (
                        <div className="menu-item__details__description">{item.description}</div>
                    ) : null}
                    {renderDietBadges() || item.calories !== '' ? (
                        <div className="menu-item__details__diet-info">
                            {(item.calories !== '' && item.calories && !preferences.calories) &&  <span className="menu-item__details__calories">{item.calories} kcal</span>}
                            {renderDietBadges()}
                        </div>
                    ) : null }
                </div>
            )
        } else {
            return null
        }
    }

    if ( item.is_vegan && !preferences.vegan ) { return null; }
    if ( item.is_vegetarian && !preferences.vegetarian ) { return null; }
    if ( item.is_gluten_free && !preferences.glutenFree ) { return null; }

    const styleVariant = variant ?? operatorContext.operator?.theme?.v3.ux.menu_layout ?? 'classic';

    return  styleVariant === 'portrait' ? (
        <StyledMenuItemPortraitWrapper>
            <StyledMenuItemPortrait
                className={`menu-item__wrapper ${item.image ? '' : 'no-image'} ${item.is_hidden ? 'unavailable-item' : ''}`}
                inBasket={basketItemCount() > 0}
                onClick={() => showItemModal(item, false)}
                variant={styleVariant}
                hasImage={item.image && allowItemImages}
            >
                <div className={'menu-item__absolute'}>
                    {item.is_hidden ? ( <div className="unavailable-item-overlay" /> ) : null}
                    {item.image && allowItemImages ? <CardImage image={item.image} className="menu-item__image" /> : null}

                    <div className="menu-item__content--portrait">
                        <div className="menu-item__header">
                            {basketItemCount() > 0 && basketItemCount() ? (
                                <span className={'menu-item__basket-amount' + (item.image && allowItemImages ? ' menu-item__basket-amount--overlay' : '')}>
                                    {basketItemCount() > 0 && basketItemCount()}
                                </span>
                            ) : null }
                            <p className="menu-item__title">
                                {/**
                                 * Change item count in basket
                                 */}

                                <span>{item.name}</span>
                            </p>
                        </div>
                        {renderDetails()}
                        <div className="menu-item__footer">
                            <div className="menu-item__footer__price">
                                {money(item.price)}
                            </div>
                            {renderTitleBadges()}
                        </div>
                    </div>
                </div>
            </StyledMenuItemPortrait>
        </StyledMenuItemPortraitWrapper>
        ) : (

        /**
         * inBasket prop if item is in basket
         */

        <StyledMenuItem
            className={`menu-item__wrapper ${item.image ? '' : 'no-image'} ${item.is_hidden ? 'unavailable-item' : ''}`}
            inBasket={basketItemCount() > 0}
            onClick={() => {
                mixpanel.track('menu item modal' + ( variant === 'upsell' ? ' (upsell)' : '') );
                trackEvent('open-item', siteContext.site?.uid ?? '')
                showItemModal(item, false)
            }}
            variant={styleVariant}
        >
            {item.is_hidden ? ( <div className="unavailable-item-overlay" /> ) : null}
            <MenuItemContent className="menu-item" variant={styleVariant}>
                <div className="menu-item__header">
                    <p className="menu-item__title">
                        {/**
                         * Change item count in basket
                         */}
                        <span className="menu-item__title__basket-amount">
                            {basketItemCount() > 0 && basketItemCount()}
                        </span>

                        <span>{item.name}</span>
                    </p>
                </div>
                {renderDetails()}
                <div className="menu-item__footer">
                    <div className="menu-item__footer__price">
                        {money(item.price)}
                    </div>
                    {renderTitleBadges()}
                </div>
            </MenuItemContent>
            {item.image && allowItemImages ? <CardImage image={item.image} className="menu-item__image" /> : null}
        </StyledMenuItem>
    );
};

interface ImageProps {
    image: string | null;
}

const MenuItemContent = styled.div<{ variant?: string }>`
    
    ${(props) => props.variant === 'classic' && `
        @media screen and (max-width: 767px) {
            width: calc(100% - 124px - 15px);
        }
        @media screen and (min-width: 768px) {
            width: calc(100% - 170px - 15px);
        }
    `}
    
    @media screen and (min-width: 768px) and (max-width: 992px) {
        width: 100%;
    }
    
    display: flex;
    flex-direction: column;
    
    .menu-item {
        &__header {
            display: flex;
            flex-direction: column;
            /* flex-grow: 1; */
        }

        &__title {
            font-size: 16px;
            margin-bottom: 4px;
            font-weight: 600;
            color: var(--text-black);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 0;
            span {
                line-height: 20px;
                vertical-align: middle;
            }
        }


        @media screen and (max-width: 1140px) {

            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }


        &__details {
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            margin-bottom: 4px;
            flex-grow: 1;
            
            .badge {
                padding: 0;
                line-height: 20px;
                font-size: 14px;
                font-weight: 500;
                border-radius: 10px;
                text-align: center;
                margin-right: 0;
                width: 16px;
            }
            .badge-vg { color: #449539; }
            .badge-gf { color: #EC750A;  }
            .badge-h { color: #EB4B7B;  }

            &__description {
                color: var(--text-grey);
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 4px;
                overflow: hidden;
                @supports (-webkit-line-clamp: 2) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
            &__diet-info {
                display: flex;
                gap: 8px;
                margin-bottom: 4px;
            }
            &__calories {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: var(--text-grey);
                display: inline-block;
            }
        }
        &__footer {
            display: flex;
            gap: 8px;
            align-items: center;
            &__price {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: var(--text-black);

                &--before {
                    text-decoration: line-through;
                    margin-left: 8px;
                    color: var(--text-grey);
                    font-weight: 400;
                }
            }
            .badge {
                margin-right: 0;
                &-style {
                    color: var(--ui_other_badge);
                    font-size: 16px;
                    line-height: 24px;
                }
                &__title {
                    padding: 0;
                    margin: 0;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    color: var(--ui_other_badge);
                    img {
                        background-color: ${(props) => rgba(0,0,0, 0.1)};
                        border-radius: 12px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
            .badge-style {
                line-height: 20px;
                display: flex;
                align-items: center;
                padding: 0;
            }
            .unavailable-item-text {
                color: var(--text-black-lite);
                margin-left: 0;
                padding-left: 0;
                font-weight: 500;
                font-size: 14px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
            }

        }
        @media screen and (min-width: 768px) {
            &__title {
                margin-bottom: 8px;
            }
            &__details {
                &__diet-info {
                    margin-bottom: 8px;
                }
                &__description {
                    margin-bottom: 8px;
                }
            }

        }

    }

    .menu-item__price {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: var(--text-black);
        /* margin-right: 8px; */

        &--before {
            text-decoration: line-through;
            margin-left: 8px;
            color: var(--text-grey);
            font-weight: 400;
        }
    }


`;


const StyledMenuItem = styled.div<{ inBasket?: boolean, variant?: string }>`
    position: relative;

    height: 164px;
    background-color: #FFFFFF;
    box-sizing: border-box;

    // @media screen and (min-width: 768px) {
    //     border: var(--card-border);
    //    
    //     /* why is this being overwritten? should it be? or should it not? */
    //     /* each card variant differs a bit, easier to separate styling for these, so commenting out... */
    //     
    //     border-radius: var(--card-radius);
    //     // box-shadow: var(--card-shadow);
    //    
    //     &:hover {
    //         &:not(.unavailable-item) { 
    //              box-shadow: 0 12px 20px 0 rgb(0 0 0 / 10%) !important;
    //         }
    //     }
    // }

    display: flex;
    flex-direction: row;
    gap: 16px;
    cursor: pointer;
    padding: 20px;
    transition: box-shadow 0.2s ease-in-out;
    
    

    ${(props) => props.inBasket && props.variant === 'classic' && `
        border-left: 4px solid var(--brand);
        padding-left: 16px !important;
    `};

    .menu-item__title__basket-amount {
        width: 20px;
        height: 20px;
        display: ${(props) => (!props.inBasket ? 'none' : 'inline-flex !important')};
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        background: var(--brand);
        font-size: 12px;
        padding: 2px 3px;
        border-radius: 4px;
        color: white;
        font-weight: 600;
        margin-right: 8px;
    }

    @media screen and (min-width: 768px) {
        &:hover {           
            box-shadow: 0 22px 24px 0 rgb(0 0 0 / 8%);
        }
    }

    .badges-diet {
        .badge {
            background-color: ${(props) => props.theme.color_shade_5};
            &.badge-vg,
            &.badge-v {
                background-color: ${(props) => rgba(props.theme.color_success, 0.1)};
                color: ${(props) => props.theme.color_success};
            }
        }
    }
    .badge--title {
        display: flex;
        align-items: center;
        &__icon {
            margin-right: 4px;
            line-height: 24px;
            // background-color: ${(props) => rgba(props.theme.v3.ui.other.badge, 0.1)};
            i { line-height: 16px }
        }
    }
    .badges-title .badge {
        color: var(--ui_other_badge);
        font-size: 14px;
        font-weight: 400;
        padding: 0;
    }

    @media screen and (max-width: 767px) {
        border-left: ${(props) => props.inBasket && `4px solid var(--brand)`};
        padding-left: ${(props) => props.inBasket && `12px; `};

        // border-bottom: 1px solid var(--border-grey)
    }

    @media screen and (min-width: 768px) {
        height: 170px;
        border-radius: var(--ui_tile_radius);
        padding: 16px;
    }

    ${(props) => props.variant === 'classic' && `
    
        box-shadow: 0 -1px 0 var(--border-grey), 0 1px 0 var(--border-grey);
        @media screen and (min-width: 768px) {
            padding: 0 !important;
            .menu-item {
                padding: 20px;
                ${props.inBasket ? `padding-left: 16px;` : ''}
                &__image {
                    height: 170px;
                    width: 170px;
                    margin-right: -1px;
                    margin-top: -1px;
                    margin-bottom: -1px;
                    border-radius: 0;
                    border-top-right-radius: var(--ui_tile_radius);
                    border-bottom-right-radius: var(--ui_tile_radius);
                }
            }
        }
        &.no-image .menu-item {
            max-width: 100% !important;
            width: auto !important;
        }
    `}
    
    ${(props) => props.theme.v3.ux.menu_tiles === 'dropshadow' && `
        @media screen and (min-width: 768px) {
            &:not(.unavailable-item) {
                box-shadow: 0px 4px 10px -4px rgb(0 0 0 / 10%), 0 2px 4px -2px rgba(0 0 0 / 5%), 0 0px 2px rgba(0 0 0 / 5%);
            }
            &:hover { box-shadow: 0 22px 24px 0 rgb(0 0 0 / 8%); }
        }
    `}
    
    ${(props) => props.theme.v3.ux.menu_tiles === 'flat' && `
        @media screen and (min-width: 768px) {
            border-radius: var(--ui_tile_radius);
            border: var(--card-border);
            ${props.inBasket ? `border-left: 4px solid var(--brand);` : ''}
            box-shadow: none;
            &:hover { box-shadow: 0 22px 24px 0 rgb(0 0 0 / 8%); }
        }
    `}
    

    ${(props) => (props.variant === 'upsell' && `
        gap: 0;
        width: 270px;
        height: 96px !important;
        border: 1px solid var(--border-grey);
        // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12) !important;
        border-radius: 8px;
        flex-shrink: 0;
        padding: 0 !important;
        overflow: visible;

        .menu-item {
            justify-content: space-between;
            padding: 12px 16px;
            flex-grow: 1;
            
            &__details__description { display: none; }
            
            &__footer__price {
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
            }
            
            &__image {
                width: 96px;
                height: 96px;
                position: relative;
                top: -1px;
                right: -1px;
                border-radius: 0;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            &__title {
                white-space: pre-wrap;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                font-size: 14px;
                line-height: 16px;
            }
        }
        
        &.no-image {
            justify-content: flex-start;
        }
    `)}
    
    .unavailable-item-overlay {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background-color: rgba(255,255,255,0.5);
        z-index: 9;
        border-radius: 4px;
    }

    &.unavailable-item:hover {
        box-shadow: none;
        cursor: default;
    }
`;

const CardImage = styled.div<ImageProps>`
    background: center center no-repeat;
    background-size: cover;
    ${(props) =>
        props.image
            ? `background-image: url('${props.image}'); background-color: transparent;`
            : null};
    width: 124px;
    height: 124px;
    flex-shrink: 0;
    border-radius: 4px;

    @media screen and (min-width: 768px) {
        width: 136px;
        height: 136px;
    }

`;


const StyledMenuItemPortrait = styled.div<{ inBasket?: boolean, variant?: string, hasImage: boolean | '' }> `
    position: relative;
    gap: 0;
    height: 100%;
    cursor: pointer;
    border-radius: var(--ui_tile_radius);
    overflow: hidden;
    background-color: white;
    transition: box-shadow 0.2s ease-in-out;
    
    .menu-item__basket-amount {
        width: 20px;
        height: 20px;
        display: ${(props) => (!props.inBasket ? 'none' : 'inline-flex !important')};
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        background: var(--brand);
        font-size: 12px;
        padding: 2px 3px;
        border-radius: 4px;
        color: white;
        font-weight: 600;
        margin-right: 8px;
        margin-bottom: 8px;
        &--overlay {
            position: absolute; 
            top: 12px;
            left: 12px;
            margin: 0;
        }
    }
    
    ${(props) => props.theme.v3.ux.menu_tiles === 'dropshadow' && `
        &:not(.unavailable-item) {
            box-shadow: 0px 4px 10px -4px rgb(0 0 0 / 10%), 0 2px 4px -2px rgba(0 0 0 / 6%), 0 0px 2px rgba(0 0 0 / 6%);    
            &:hover {
                box-shadow: 0 12px 20px 0 rgb(0 0 0 / 10%) !important;
            } 
        }
    `}
    
    
    ${(props) => props.theme.v3.ux.menu_tiles === 'flat' && `
        
        border-radius: var(--ui_tile_radius);
        border: 1px solid #EAEAEA;
        box-shadow: none;
        &:hover {
            box-shadow: 0 12px 20px 0 rgb(0 0 0 / 10%) !important;
        }
        
    `}
  
    
    .menu-item__details__description { display: none; }
    
    .menu-item__image {
        order: 1;
        flex-grow: 1;
        width: calc(100% + 2px);
        object-fit: cover;
        position: relative;
        top: -1px;
        right: -1px;
        left: -1px;
        margin-bottom: -1px;
        border-radius: 8px 8px 0 0;
    }

    &.no-image {
        justify-content: flex-start;
    }
    .badges-diet {
        .badge {
            background-color: ${(props) => props.theme.color_shade_5};
            &.badge-vg,
            &.badge-v {
                background-color: ${(props) => rgba(props.theme.color_success, 0.1)};
                color: ${(props) => props.theme.color_success};
            }
        }
    }
    .badge--title {
        display: flex;
        align-items: center;
        &__icon {
            margin-right: 4px;
            line-height: 24px;
            padding-top: 1px;
            // background-color: ${(props) => rgba(props.theme.v3.ui.other.badge, 0.1)};
            i { 
                font-size: 4vw; 
                line-height: 100%;
                @media screen and (min-width: 480px) {
                    font-size: 16px;
                }
            }
        }
    }
    .badges-title .badge {
        color: var(--ui_other_badge);
        font-size: 14px;
        font-weight: 400;
        padding: 0;
    }
  
    @media screen and (min-width: 480px) {
        padding-top: 105%;
    }
    
    .menu-item__absolute {
        @media screen and (min-width: 480px) {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
        display: flex;
        flex-direction: column;
    }

    .menu-item__content--portrait {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex-basis: 104px;
        padding: 16px 16px 16px 12px;
        order: 2; 
        border-left: solid 4px transparent;
        ${(props) => props.inBasket ? 'border-color: var(--brand);' : ''}
        .menu-item {
            &__header {
                display: flex;
                flex-direction: column;
                /* flex-grow: 1; */
            }
    
            &__title {
                margin: 0;
                font-weight: 600;
                font-size: 4vw;
                @media screen and (min-width: 480px) {
                    font-size: 16px;
                }
                color: var(--text-black);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 0;
                span {
                    line-height: 20px;
                    vertical-align: middle;
                }
                &__basket-amount {
                    width: 20px;
                    height: 20px;
                    display: ${(props) => (!props.inBasket ? 'none' : 'inline-flex !important')};
                    justify-content: center;
                    align-items: center;
                    color: #FFFFFF;
                    background: var(--brand);
                    font-size: 12px;
                    padding: 2px 3px;
                    border-radius: 4px;
                    color: white;
                    font-weight: 600;
                    margin-right: 8px;
                }
            }
    
    
            &__details {
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 14px;
                
                .badge {
                    padding: 0;
                    line-height: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    border-radius: 10px;
                    text-align: center;
                    margin-right: 0;
                    width: 16px;
                }
                .badge-vg { color: #449539; }
                .badge-gf { color: #EC750A;  }
                .badge-h { color: #EB4B7B;  }
    
                &__description {
                    color: var(--text-grey);
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    overflow: hidden;
                }
                &__diet-info {
                    display: flex;
                    gap: 8px;
                }
                &__calories {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500;
                    color: var(--text-grey);
                    display: inline-block;
                }
            }
            &__footer {
                display: flex;
                gap: 4px;
                align-items: end;
                flex-grow: 1;
                @media screen and (min-width: 480px) {
                    gap: 8px;
                }

                &__price {
                    font-weight: 500;
                    font-size: 4vw;
                    line-height: 100%;
                    line-height: 24px;
                    @media screen and (min-width: 480px) {
                        font-size: 16px;
                        padding: 0;
                    }
                    color: var(--text-black);
    
                    &--before {
                        text-decoration: line-through;
                        margin-left: 8px;
                        color: var(--text-grey);
                        font-weight: 400;
                    }
                }
                .badge {
                    margin-right: 0;
                    &-style {
                        color: var(--ui_other_badge);
                        font-size: 4vw;
                        @media screen and (min-width: 480px) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    &__title {
                        padding: 0;
                        margin: 0;
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        color: var(--ui_other_badge);
                        img {
                            background-color: ${(props) => rgba(0,0,0, 0.1)};
                            border-radius: 12px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
                .badge-style {
                    line-height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 0;
                    @media screen and (min-width: 480px) {
                        line-height: 24px;
                    }
                    
                }
                .unavailable-item-text {
                    color: var(--text-black-lite);
                    margin-left: 0;
                    padding-left: 0;
                    font-weight: 500;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    display: inline-block;
                    
                    font-size: 4vw;
                    line-height: 100%;
                    padding: 2px 0;
                    @media screen and (min-width: 480px) {
                        font-size: 14px;
                        padding: 0;
                        line-height: 24px;
                    }
                }
    
            }
        }
    
        .menu-item__price {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: var(--text-black);
            /* margin-right: 8px; */
    
            &--before {
                text-decoration: line-through;
                margin-left: 8px;
                color: var(--text-grey);
                font-weight: 400;
            }
        }
    }
    .unavailable-item-overlay {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background-color: rgba(0,0,0,0.03);
        z-index: 9;
        border-radius: var(--ui_tile_radius);
        @media screen and (min-width: 768px){
            background-color: rgba(255,255,255,0.5);
        }
    }
`;

const StyledMenuItemPortraitWrapper = styled.div`
    margin: 0;
    @media screen and (min-width: 768px){
        margin: 0;
    }
`;

export default MenuItem;
