import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../../../contexts/basket/useBasketContext';
import { Item } from '../../../../../contexts/site/site-context';
import { money } from '../../../../../services/format';
import QuantityButton from '../items/modal/components/QuantityButton';
import {iLog} from "../../../../../index";
import mixpanel from "mixpanel-browser";
import useOperatorContext from "../../../../../contexts/operator/useOperatorContext";

type OrderItemProps = {
    item: Item;
    index: number;
    showItemModal: (item: Item, edit: boolean) => void;
};

const OrderItem = ({ item, index, showItemModal }: OrderItemProps) => {
    const basketContext = useBasketContext();
    const operatorContext = useOperatorContext();

    const [quantity, setQuantity] = useState(item?.quantity ?? 1);


    //const [editModalVisible, setEditModalVisible] = useState(false);
    //const [originalItem, setOriginalItem] = useState<Item | null>(null);

    /*useEffect(() => {
        let cloned= JSON.parse(JSON.stringify(item)) as Item;
        console.log('cloning', cloned);
        setOriginalItem(cloned);
    }, []);*/


    /*const getMaxLimit = (group: ModifierGroup) => {
        let maxLimitReached = false;
        let checked = 0;
        group.modifiers.forEach((mod) => {
            if (mod.selected === true) {
                checked = checked + (mod.quantity ?? 1);
                if (checked >= group.max) {
                    maxLimitReached = true;
                }
            }
        });

        return maxLimitReached;
    };

    const onCancel = () => {
        // reset item back to original state
        setQuantity(originalItem?.quantity ?? 1);
        const itm: Item = { ...originalItem } as Item;
        itm.modifiersTotal = calculateModifiersPrice(itm);
        const index = basketContext?.basketItems?.findIndex((i) => i.uid === itm.uid);
        basketContext.addItem(itm, originalItem?.quantity ?? 0, true, index);
        setEditModalVisible(false);
    };

    const renderModifierGroups = (groups: ModifierGroup[]) => {
        if (groups.length <= 0) {
            return null;
        }

        return (
            <>
                {groups.map((group, index) => (
                    <ModifierGroupWrapper className="mod-group" key={`group-${group.uid}-${index}`}>
                        <p className="mod-group__title">
                            {group.name}
                            {group.min > 0 ? (
                                <span className="required">
                                    {t`Required`} {group.min > 1 ? `(MIN ${group.min})` : ''}
                                </span>
                            ) : null}
                        </p>
                        <p className="mod-group__subtitle">{group.subtitle}</p>
                        {renderModifierItems(group.modifiers, group.max > 1, getMaxLimit(group))}
                    </ModifierGroupWrapper>
                ))}
            </>
        );
    };

    const renderModifierItems = (modifierItems: Modifier[], multiChoice: boolean, maxReached: boolean) => {
        return modifierItems.map((modifierItem, index) => {
            return (
                <div key={`mItem-${modifierItem.uid}-${index}`}>
                    {/!* {modifierItem.name} *!/}
                    <ModifierCheckbox
                        modifier={modifierItem}
                        type={multiChoice ? 'checkbox' : 'radio'}
                        selected={modifierItem.selected ?? false}
                        maxReached={maxReached}
                        updateModifier={updateModifier}
                    />
                </div>
            );
        });
    };

    const updateModifier = (updatedModifier: Modifier, multi: boolean) => {
        // Map updated mod into selectedItem
        let newItem: Item = { ...item };
        let found = false;

        newItem.modifier_groups = newItem.modifier_groups.map((group) => {
            if (group.uid !== updatedModifier.group_uid) {
                return group;
            }

            group.modifiers = group.modifiers.map((mod) => {
                if (mod.uid === updatedModifier.uid) {
                    if (!updatedModifier.quantity && updatedModifier.selected) {
                        updatedModifier.quantity = 1;
                    }
                    found = true;
                    return updatedModifier;
                }

                // Unselect others if radio
                if (mod.selected && !multi) {
                    mod.quantity = 0;
                    mod.selected = false;
                }

                return mod;
            });

            return group;
        });

        // calculate modifiers total
        newItem.modifiersTotal = calculateModifiersPrice(newItem);
        // console.log('update modifier, match found?', found, newItem);
        const index = basketContext.basketItems.findIndex((i) => i.uid === newItem.uid);
        basketContext.addItem(newItem, quantity, index >= 0, index);
    };*/

    /*const renderTitleBadges = () => {
        let badges = [];
        if (item.is_new) {
            badges.push(
                <Badge key={`tfbg-${item.uid}-new`}>
                    <FontAwesomeIcon className="badge__icon" size="sm" icon={faStar} />
                    New
                </Badge>,
            );
        }

        if (item.is_special) {
            badges.push(
                <Badge key={`tfbg-${item.uid}-special`}>
                    <FontAwesomeIcon className="badge__icon" size="sm" icon={faStar} />
                    Special
                </Badge>,
            );
        }

        if (badges.length > 0) {
            return badges.map((badge) => badge);
        }

        return null;
    };*/

    useEffect( () => {

        if ( quantity < 1 ) {
            iLog('OrderItem: quantity now 0. Remove item.', item);
            mixpanel.track('Item removed (qty 0)');
            basketContext.removeItem(item);
            return;
        }

        if ( item.quantity !== quantity ) {
            iLog('OrderItem: quantity has changed', [item.name, quantity, item.quantity !== quantity]);
            basketContext.addItem(item, quantity, true);
        }

    }, [ quantity ])

    useEffect( () => {
        if ( item.quantity ) {
            setQuantity(item.quantity);
        }
    }, [ item ]);

    const renderItemTimeRestrictionError = () => {
        if ( !operatorContext.operator?.has_timed_categories ) {
            return null;
        }


        return (
            <div className={'b-item__error'}>
                <i className="feather feather-alert-circle feather-20 color-filter-invalid" />
                <span>Item unavailable at your collection time</span>
            </div>
        );
    };

    return (
        <Layout debug_error_style={index % 2 === 0} onClick={() => {
            mixpanel.track('Edit item clicked');
            showItemModal(item, true)
        }}>
            {/*<ModalWrapper>
                <SmallModal
                    onHide={() => setEditModalVisible(false)}
                    show={editModalVisible}
                    showClose={false}
                    scrollHeader={true}
                    header={<img className="closeBtn" style={{cursor: "pointer"}} src={modal_close} onClick={onCancel} />}
                    footer={
                        <>
                            <style>{`
                               .footerContent {
                                    width: 552px;
                                    max-width: 100vw;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin: 0 auto;
                                }

                                @media screen and (max-width: 786px) {
                                    .footerContent {
                                        justify-content: space-between;
                                        padding-left: 0px;
                                    }
                                }
                            `}</style>
                            <div className="footerContent">

                                <QuantityButton
                                    quantity={quantity}
                                    setQuantity={setQuantity}
                                />

                                <ItemUpdateButton
                                    validated={true}
                                    disabled={false}
                                    edit={true}
                                    total={item.modifiersTotal + item.price}
                                    loading={false}
                                    onClick={() => {
                                        setOriginalItem(JSON.parse(JSON.stringify(item)) as Item);
                                        setEditModalVisible(false);
                                    }}
                                />
                            </div>
                        </>
                    }
                >
                    <EditContent>
                        <div className="header">
                             <h2>{item.name}</h2>
                            {item.image && <ItemImage className="d-sm-none" image={item.image} />}

                            {item.image ? <ItemImage className="d-none d-sm-block" image={item.image} /> : null}

                            <h5 className="main-title align-items-center align-items-sm-end justify-content-between justify-content-sm-start">
                                {item.name}
                                 {renderDietBadges() ? (
                                    <span className="badges-diet">{renderDietBadges()}</span>
                                ) : null}
                            </h5>

                            <span className="description">{item.description ? <p>{item.description}</p> : null}</span>
                            {renderTitleBadges() ? (
                                <div className="badges-title my-2">{renderTitleBadges()}</div>
                            ) : null}
                             {item.allergens ?
                                <Alert icon={faInfoCircle} variant={"info"}>
                                    <span className="p-title">Allergens</span>
                                    {item.allergens}
                                </Alert>
                                : null}
                            <Nutrients className="cals">
                                 To do: real data
                                880 kcal
                                <img src={infoImg} alt="" />
                                <span>Adults need around 2000 kcal a day</span>
                            </Nutrients>
                            {item.allergens && (
                                <Allergens>
                                    {item.allergens.split(',').map((item) => (
                                        <span>{item}</span>
                                    ))}
                                </Allergens>
                            )}

                            <div className="mod-list">{renderModifierGroups(item.modifier_groups)}</div>
                        </div>
                    </EditContent>
                </SmallModal>
            </ModalWrapper>*/}



            <Details>
                <div className={"line-item" + (index % 2 === 0 ? ' line-item--unavailable' : '')}>
                    <div className="b-item-name">
                        {item.name}
                    </div>
                    <div className={'b-item-values'}>
                        <QuantityButton
                            quantity={quantity}
                            setQuantity={setQuantity}
                            type={'small'}
                        />
                        <div className="b-item-price">
                            {money(item.price * (item.quantity ?? 1) + (item.modifiersTotal ?? 0) * (item.quantity ?? 1))}
                        </div>
                    </div>
                </div>
                {item.modifiers?.length ? (
                    <div className="b-item-mod">
                        {item.modifiers?.map((modifier, index) => {

                            // item's group
                            let group = item.modifier_groups.find((mg) => mg.uid === modifier.group_uid);

                            if ( group && !group?.multi_choice && modifier.quantity === -1 ) {
                                return '';
                            }

                            return <span key={`basket-item-mod-${modifier.uid}`}>
                                { modifier.quantity === -1 ? 'No '+ modifier.name : modifier.name }
                                { modifier.quantity > 1 ? ' ('+ modifier.quantity +')' : '' }
                                { item.modifiers && index < item.modifiers?.length - 1 ? ', ' : ''}
                            </span>;
                        })}
                    </div>
                ) : null}

                {renderItemTimeRestrictionError()}

                {/*<div className="b-item-controls">*/}
                {/*     /!*<span className="b-item-remove" onClick={() => basketContext.removeItem(item)}>{t`Remove`}</span>*!/*/}

                {/*    /!* To do: add functionality *!/*/}
                {/*    <span className="b-item-edit" onClick={() => {*/}
                {/*        mixpanel.track('Edit item clicked');*/}
                {/*        showItemModal(item, true)*/}
                {/*    }}>{t`Edit item`}</span>*/}
                {/*</div>*/}
            </Details>

        </Layout>
    );
};

const ModalWrapper = styled.div`
    .modal-content {
        padding: 0 !important;
    }

    .closeBtn {
        cursor: pointer;
        opacity: 1;
    }

    button.close {
        display: none;
    }
`;

const EditContent = styled.div`
    .modal-header {
        padding-bottom: 8px !important;
        height: 104px !important;
        box-shadow: none !important;

        @media screen and (max-width: 768px) {
            outline: 1px solid #e7e7e7 !important;
        }
    }

    .close {
        right: auto !important;
        left: 16px !important;
        display: block !important;
        transform: none !important;
        position: initial !important;
        opacity: 1 !important;
        transition: background-color 0.15s ease-in-out;
        :hover {
            background-color: var(--border-grey);
        }
    }

    .mod-list {
        margin-top: 16px;

        @media screen and (max-width: 982px) {
            margin-top: 8px;
        }

        .mod-group {
            > p {
                padding: 0px 0px;
            }

            > * {
                > div {
                    padding: 7px 0px 7px 0;

                    label {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    h5 {
        font-size: 32px;
        .allergens {
            font-size: 20px;
        }
    }

    .description {
        color: var(--text-grey);

        p {
            margin-bottom: 0;
        }
    }

    .cals {
        margin-top: 7px;
        font-size: 14px;
        color: var(--text-grey);

        @media screen and (max-width: 768px) {
            margin-top: 14px;
        }
    }

    .main-title {
        display: flex;
        align-items: flex-end;
        font-size: 24px !important;
        margin-bottom: 8px;

        @media screen and (max-width: 768px) {
            margin-bottom: 9px;
        }

        .badges-diet {
            font-size: 16px;
            .badge {
                padding: 0 4px;
                font-size: 16px;
                margin-right: 4px;
                font-weight: 600;
                font-family: ${(props) => props.theme.bodyFont.name};
                &:last-child {
                    margin-right: 0;
                }

                background-color: rgba(68, 149, 57, 0.1);
            }
            @media screen and (min-width: 576px) {
                font-size: 20px;
                margin-left: 10px;
                &::before {
                    content: '(';
                }
                &::after {
                    content: ')';
                }
                .badge {
                    padding: 0;
                    margin: 0;
                    font-size: 20px;
                    background: none;
                    font-family: ${(props) => props.theme.font.name};
                    &::after {
                        content: '/';
                    }
                    &:last-child {
                        &::after {
                            content: '';
                        }
                    }
                }
            }
        }
    }
    .item-modal-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0;
    }
    .modal-footer {
        form {
            width: 140px;
            .form-group,
            .form-control {
                margin-bottom: 0;
            }
            select {
                height: 54px;
                text-align: center;
                font-size: 18px;
                color: ${(props) => props.theme.color_shade_1};
                font-weight: 700;
                border: 1px solid ${(props) => props.theme.color_border};
                box-sizing: border-box;
                border-radius: 10px;
            }
        }
    }
    // Badges
    .badges-diet .badge {
        /* background-color: ${(props) => props.theme.color_shade_5}; */
        font-weight: 400;
        &.badge-vg,
        &.badge-v {
            /* background-color: ${(props) => rgba(props.theme.color_success, 0.1)}; */
            color: ${(props) => props.theme.color_success};
        }
    }
    .badges-title .badge {
        font-weight: 400;
        color: ${(props) => props.theme.color_primary};
        padding: 0;
        /* background-color: ${(props) => rgba(props.theme.color_primary, 0.1)}; */
    }
`;

const Allergens = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
    margin-top: 12px;
    margin-bottom: 8px;

    @media screen and (max-width: 768px) {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    span {
        background: #e7e7e7;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 500;
    }
`;

const ModifierGroupWrapper = styled.div`
    margin-bottom: 20px;
    font-size: 14px;
    .mod-group__title {
        font-size: 16px;
        color: var(--text-black);
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        justify-content: space-between;
        font-weight: 500;

        .required {
            border-radius: 4px;
            text-transform: none;
            color: var(--text-grey);
            font-family: ${(props) => props.theme.bodyFont.name};
            font-weight: 400;
            font-size: 14px;
            background: rgba(0, 205, 0, 0.1);
            padding: 0px 12px;
        }

        @media screen and (max-width: 576px) {
            font-size: 16px;
            font-weight: 600;
            font-family: ${(props) => props.theme.bodyFont.name};
            color: var(--text-black);
            text-transform: none;
        }
    }
    .mod-group__subtitle {
        margin-bottom: 4px;
        margin-top: -3px;
        color: var(--text-grey);
    }
`;

const Nutrients = styled.div`
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: var(--text-black);

    .bullet {
        width: 4px;
        height: 4px;
        background-color: var(--text-grey);
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px;
    }

    img {
        height: 14px;
        width: auto;
    }
    span {
        font-size: 14px;
        margin-left: 6px;
        color: var(--text-grey);
    }

    @media screen and (max-width: 576px) {
        font-size: 14px;
    }
`;

const Details = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    .line-item { 
        display: flex;
        gap: 12px;
        align-items: flex-start;
        margin-bottom: 4px;
    }
    
    .b-item__error {
        margin-top: 8px;
        color: var(--invalid);
        display: flex;
        gap: 8px;
        align-items: center;
    }
    
    .b-item-name {
        flex-grow: 1;
    }

    .b-item-name,
    .b-item-mod,
    .b-item-price {
        font-size: 16px;
        line-height: 24px;
        color: var(--text-black);
        font-weight: 400;
    }
    
    .b-item-values {
        flex-shrink: 0;
        gap: 12px;
        display: flex;
        align-items: center;
    }

    .b-item-remove {
        color: var(--invalid);
        cursor: pointer;
        user-select: none;
    }


    .b-item-mod {
        font-size: 14px;
        line-height: 20px;
        color: var(--text-grey);
    }

    .b-item-controls {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;

        margin-top: 16px;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .b-item-edit {
            color: var(--brand);
            cursor: pointer;
            user-select: none;
            font-weight: 500;
            display: inline-block;
            position: relative;
            ::after {
                position: absolute;
                content: '';
                left: 0;
                right: 0;
                bottom: -2px;
                border-bottom: 1px solid transparent;
                transition: border-color 0.15s ease-in-out;
            }
            :hover {
                ::after {
                    border-color: var(--brand);
                }
            }
        }

    }
`;

const Quantity = styled.div`
    display: flex;
    padding-right: 12px;
    flex-shrink: 0;
    div {
        span {
            text-align: center;
            display: block;
            padding-right: 15px;
            min-width: 28px;
            padding: 4px;
            border-radius: 6px;
            background-color: ${(props) => rgba(props.theme.color_primary, 0.1)}; // PRIMARY RED
            color: ${(props) => props.theme.color_primary};
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }
    }
    @media screen and (min-width: 768px) {
        padding-right: 16px;
    }
`;

const ItemImage = styled.div<{ image: string }>`
    width: 100%;
    border-radius: 4px;
    margin-bottom: 16px;
    height: 229px;
    background: no-repeat center center;
    background-size: cover;
    background-image: url('${(props) => props.image}');
    background-position: center;
    /* background-size: 140%; */
    background-size: cover;

    @media screen and (min-width: 576px) {
        height: 280px;
    }
`;

const QuantityWrapper = styled.div`
    display: flex;
    margin-right: 8px;
    span {
        width: 24px;
        height: 24px;
        font-size: 15px;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        font-weight: 700;
        button {
            padding: 0;
            border: none;
            margin: 0;
            font: inherit;
            color: inherit;
            background-color: transparent;
            text-align: inherit;
            cursor: pointer;
            &:focus {
                outline: none;
                svg {
                    border-radius: 25px;
                    box-shadow: 0px 0px 0px 4px ${(props) => rgba(props.theme.color_secondary, 0.1)};
                }
            }
        }
    }
`;

const Layout = styled.div<{debug_error_style: boolean}>`
    width: 100%;
    border-bottom: 1px solid var(--border-grey);
    cursor: pointer;
    padding: 20px;
    &:hover {
        background-color: var(--brand-10);
    }
    
    ${(props) => props.debug_error_style ? `
        // border-color: var(--invalid);
        // box-shadow: inset 0 0 0 1px var(--invalid);
    ` : ''}
`;

/*function calculateModifiersPrice(newItem: Item) {
    let total = 0;
    newItem.modifier_groups.forEach((group) => {
        let groupModsSelected = 0;

        group.modifiers.forEach((mod) => {
            if (mod.selected || mod.quantity) {
                // selectedMods.push(mod);
                groupModsSelected += mod.quantity ?? 1;
                total += mod.price * (mod.quantity ?? 1);
            }
        });
    });

    return total;
}*/

export default OrderItem;
