import axios, { AxiosResponse } from 'axios';
import { t } from 'ttag';
import { toastError } from '../../hooks/toastError';
import { iLog } from "../../index";
import toaster from "toasted-notes";

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false,
});

api.interceptors.request.use(function (config) {

    toaster.closeAll();

    return config;
}, function (error) {
    return Promise.reject(error);
});

export type APIError = {
    app: APIAppError[];
    input: APIInputError[];
};

export type APIAppError = {
    code: number;
    message: string;
    extra_data: any[];
};

export type APIInputError = {
    code: number;
    message: string;
    extra_data: any[];
};

export enum APIErrorCode {
    TimeslotMismatch = 844,
    TimeslotPast = 847,
    TimeslotExpired = 848,
    TableNotFound = 804,
    Stripe3DSRequired = 972,
    Unauthorized = 499
}

// CHECKING FOR VERSION AGREEMENT
export const HasVersionChanged = async (): Promise<boolean> => {

    let AssetManifest = window.sessionStorage.getItem('manifest');

    if ( AssetManifest === null || AssetManifest === '' ) {
        return false;
    }

    let AssetManifestURL = '//' + window.location.hostname + ( process.env.REACT_APP_ENV === 'local' ? ':3000' : '' ) + '/asset-manifest.json' ?? '';

    try {

        let resp = await fetch(AssetManifestURL, { method: 'get', mode: 'cors' });
        let obj = await resp.json();

        return JSON.stringify(obj) !== AssetManifest;

    } catch (err) {
        console.error('CheckForVersionChange', err);
        return false;
    }
};

export async function handleResponse(response: AxiosResponse) {

    if ( await HasVersionChanged() ) {
        // todo: track this in logs..
        // todo: testing!
        window.location.reload();
    }


    if (response.data) {
        if (!response.data.data && response.data.errors) {
            iLog('API Error has occurred', response.data.errors);
            throw response.data.errors as APIError;
        }

        if (response.data.flow && typeof response.data.data === 'object') {
            response.data.data.flow = response.data.flow;
        }
        return response.data.data;
    }

    return response;
}

export function handleError(error: any) {
    // Error 😨
    console.error('Handling API error...', error);

    if (error && (error.app || error.input)) {
        // App/Input related error from the API
        error.app.forEach((error: APIAppError) => {

            // Exclusions from Toaster
            // we like to show errors inline where possible
            // 919-946: Promo errors
            // 972: 3DS
            // 851: customer validation
            // 803: postcode
            // 847-848,862: timeslots

            let excluded_error_codes = [
                ...Array.from({length: 946 - 919 + 1}, (value, index) => 919 + index),
                972,
                851,
                847, 848, 862,
                803

            ];


            if ( !excluded_error_codes.includes(error.code) ) {
                toastError(error.message ?? t`Sorry, there has been an unexpected error. Please try again.`);
            }

            iLog('ok message: ', [error.message, error.code]);

            // Maybe throw the error here?
            // if (error.code === APIErrorCode.TimeslotMismatch) {
            //     alert('timeslot error');
            // }

            throw error;
        });

        if (typeof error.input === 'object') {
            //TODO: Remove this, only because object accidentally coming from API
            throw error.input;
        } else {
            error.input.forEach((error: APIInputError) => {
                toastError(error.message ?? t`Sorry, there has been an unexpected error. Please try again.`);

                iLog('Input error message: ', [error.message, error.code]);
                throw error;
            });
        }

        iLog('!!!!!!!', error);
    } else if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        iLog('error in response', [error.response.data, error.response.status, error.response.headers]);

        // This handles situations where the API says the request is unauthorized
        // but the custom header hash (x-orderswift) is provided which would
        // generally indicate a valid client is making the request..

        if ( error.response.status === APIErrorCode.Unauthorized ) {
            window.location.reload();
        }

        // ..the reloading is not infinite because the API will store the attempts in
        // cache and if it occurs multiple times will return a different code.

        var errorMessage = '';

        switch (error.response.data.error?.code ?? 917) {
            case 901:
                errorMessage = t`Sorry, this restaurant is currently closed. Please try again later.`;
                break;
            case 902:
            case 903:
                errorMessage = t`TODO: Timeslot issue popup`;
                break; //TODO: replace
            case 904:
                errorMessage = t`Sorry, this restaurant does not currently deliver to this postcode.`;
                break;
            case 905:
                errorMessage = t`As you have placed an order very recently, we have been unable to complete this new order.`;
                break;
            case 906:
                errorMessage = t`Sorry, we have been unable to process your payment. Please try again.`;
                break;
            case 907:
                errorMessage = t`Sorry, this order exceeds the maximum amount allowed by the restaurant.`;
                break;
            case 908:
                errorMessage = t`Sorry, this order does not reach the minimum amount of the restaurant.`;
                break;
            case 909:
                errorMessage = t`Sorry! The postcode supplied does not match the postcode this card is registered to. Please contact your card issuer. Note this may have placed a pending charge against your card.`;
                break;
            case 910:
                errorMessage = t`Sorry, there has been an error processing this card. Please try another.`;
                break;
            case 912:
                errorMessage = t`Sorry, there has been an error processing this payment. Please check you have sufficient funds.`;
                break;
            case 913:
                errorMessage = t`Sorry, there has been an issue processing your order. Please try again.`;
                break;
            case 914:
                errorMessage = t`Sorry, ${error.response.data.error.extra_data.item_name} is not currently available. Please remove it from your basket.`;
                break;
            case 915:
                errorMessage = t`Sorry, this item is currently unavailable. Please try again later.`;
                break;
            case 916:
                errorMessage = t`Sorry, we have been unable to process your order. Please try again.`;
                break;
            case 917:
                errorMessage = t`Sorry, there has been an unexpected error. Please try again.`;
                break;
            case 918:
                errorMessage = t`Sorry, there has been an error with the personal info given. Please check and try again.`;
                break;
            case 919:
                errorMessage = t`Sorry, there has been an error with the discount code. Please try again.`;
                break;
            case 920:
                errorMessage = t`Sorry, we couldn't identify your delivery postcode. Please double-check your input.`;
                break;
            case 921:
                errorMessage = t`Sorry, the restaurant is too busy right now to accept your order. Please try again in 15 minutes.`;
                break;
            case 922:
                errorMessage = t`Sorry, ${error.response.data.error.extra_data.msg}`;
                break;
            case 929:
                errorMessage = t`Sorry, we were unable to apply your promotion code for technical reasons.`;
                break;
            case 930:
                errorMessage = t`Sorry, a promotion code has already been applied. Just one code per order can be applied.`;
                break;
            case 931:
                errorMessage = t`Sorry, the promotion code entered is invalid.`;
                break;
            case 932:
                errorMessage = t`Sorry, the promotion code entered is not live.`;
                break;
            case 933:
                errorMessage = t`Sorry, the promotion code entered is not valid for ${error.response.data.error.extra_data.msg}.`;
                break;
            case 934:
                errorMessage = t`Sorry, the promotion code entered is not valid at this restaurant.`;
                break;
            case 935:
                errorMessage = t`Sorry, the promotion code has expired.`;
                break;
            case 936:
                errorMessage = t`Sorry, the promotion code entered is not live yet.`;
                break;
            case 937:
                errorMessage = t`Sorry, the promotion code has expired.`;
                break;
            case 938:
                errorMessage = t`Sorry, please enter an email address.`;
                break;
            case 939:
                errorMessage = t`Sorry, please check your email address.`;
                break;
            case 940:
                errorMessage = t`Sorry, the promotion code is not valid for the selected day.`;
                break;
            case 941:
                errorMessage = t`Sorry, the promotion code is not valid for the selected time.`;
                break;
            case 942:
                errorMessage = t`Sorry, this promotion has been used too many times.`;
                break;
            case 943:
                errorMessage = t`Sorry, this promotion cannot be applied to the items in your basket.`;
                break;
            case 944:
                errorMessage = t`Sorry, the promotion code can only be applied to baskets of ${error.response.data.error.extra_data.msg} or more.`;
                break;
            case 945:
                errorMessage = t`Sorry, the promotion code can only be applied to baskets of ${error.response.data.error.extra_data.msg} or less.`;
                break;

            case 950:
                errorMessage = t`Sorry, we have been unable to process this payment. Please check your card details and try again.`;
                break;
            case 951:
                errorMessage = t`Sorry, this payment has been declined. Please try another payment card.`;
                break;
            case 952:
                errorMessage = t`Sorry, ${error.response.data.error.extra_data.msg}`;
                break;

            case 960:
                errorMessage = t`Sorry, we couldn't find your table number. Please check your input and try again.`;
                break;
            case 970:
                errorMessage = t`Sorry, we couldn't process your payment attempt if you've changed your email address. Please create a new order.`;
                break;
            case 971:
                errorMessage = t`The postcode supplied does not match the postcode this card is registered to. Please contact your card issuer. Note this may have placed a pending charge against your card.`;
                break;
            default:
                errorMessage = error.response.data.error.message;
        }

        // alert(errorMessage);

        toastError(errorMessage);

        throw new Error(errorMessage);
        // else display a error banner
    } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        // alert('Error: A connection issue has been detected. (' + error.message + ')');
        // toaster.notify('Error: A local connection issue has been detected. (' + error.message + ')', {
        //     duration: 7000
        // });
        toastError(t`Error: A local connection issue has been detected. (` + error.message + ')');

        iLog('error again', error.request);
        // navigate("/logout");
        // window.alert("You have been automatically logged out. (2)");
    } else {
        // Something happened in setting up the request and triggered an Error
        iLog('Error', error);
        // alert('Error: Request error occurred. Please reload the page. (' + error.message + ')');
        toastError(error.message);

        throw new Error(error.message);
    }
    iLog('error config', error.config);
}

export default api;
