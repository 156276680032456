import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../../../contexts/basket/useBasketContext';
import { Item } from '../../../../../contexts/site/site-context';
import { money } from '../../../../../services/format';

type BasketItemProps = {
    item: Item;
    handleClick: () => void;
    checkout: boolean;
    last?: boolean
};

const BasketItem = ({ item, handleClick, checkout, last }: BasketItemProps) => {
    const basketContext = useBasketContext();

    return (
        <Layout border={!last}>
            {/* <Quantity checkout={checkout}>
                <div>
                    <span onClick={handleClick}>{item.quantity ?? 1}</span>
                </div>
            </Quantity> */}
            <Details checkout={checkout}>
                <div className='titlePrice'>
                    <Quantity onClick={handleClick}>
                        <div>
                            <span>
                                {item.quantity ?? 1}
                            </span>
                        </div>
                    </Quantity>
                    <div className="b-item-content">
                        <span className="b-item-name" onClick={handleClick}>
                        { item.name }
                        </span>
                        {item.modifiers?.length ? (
                            <div className="b-item-mod flex-column">
                                {item.modifiers?.map((modifier, index) => {

                                   if ( !modifier.display ) return '';

                                    return <p className='p-0 m-0 modifierItem' key={`modifierItem-${index}`}>
                                        { modifier.quantity === -1 ? 'No '+ modifier.name : modifier.name +
                                            ((modifier.quantity ?? 0) > 1 ? ` (${modifier.quantity})` : '') +
                                            (item.modifiers?.length === index + 1 ? '' : ', ')}
                                    </p>

                                })}
                            </div>
                        ) : null}
                    </div>


                    <span className="b-item-price">
                        {/*{money(item.price * (item.quantity ?? 1) + (item.modifiersTotal ?? 0) * (item.quantity ?? 1))}*/}
                        {money(
                            item.item_total ??
                                item.price * (item.quantity ?? 1) + (item.modifiersTotal ?? 0) * (item.quantity ?? 1),
                        )}
                    </span>
                </div>


                {checkout ? null : (
                    <div>
                        <span
                            className="b-item-remove"
                            onClick={() => basketContext.removeItem(item)}
                        >{t`Remove`}</span>
                    </div>
                )}
            </Details>
        </Layout>
    );
};


const Quantity = styled.div`
    display: flex;
    padding-right: 8px;
    flex-shrink: 0;
    div {
        span {
            display: block;
            min-width: 28px;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
        }
    }
`;



const Details = styled.div<{ checkout: boolean }>`
    max-width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 8px 0;
    > div {
        display: flex;

        &:last-child {
            margin-bottom: 0;
        }
        .b-item-content {
            flex-grow: 1
        }
        .b-item-name {
            font-size: 15px;
            font-weight: 400;
            color: ${(props) => props.theme.color_shade_1};
            display: block;
            width: 100%;
            line-height: 24px;
            ${(props) => (props.checkout ? null : 'cursor: pointer;')}
        }

        .b-item-qty {
            
            display: block;
            margin-right: 17px;
            margin-left: 6px;
        }

        .b-item-price {
            font-size: 15px;
            padding-left: 12px;
            line-height: 24px;
        }
        .b-item-remove {
            color: var(--invalid);
            cursor: pointer;
            font-size: 13px;
            user-select: none;
        }
    }


    .b-item-mod {
        margin-top: 4px;
        font-size: 13px;
        color: var(--text-black);
    }

    .divider {
        width: 100vw;
        height: 1px;
        background-color: var(--text-grey);
        position: relative;
        left: -16px;
        margin: 24px 0 16px 0;
    }
`;

const Layout = styled.div<{border?: boolean}>`
    padding: 0 20px;
    @media screen and (min-width: 993px) {
        padding: 0;
    }
    display: flex;
    width: 100%;
    // ${(props) => props.border ? `border-bottom: 1px solid var(--border-grey);` : null}
    .modifierItem {
        font-size: 14px;
        color: var(--text-grey);
    }
`;

export default BasketItem;
