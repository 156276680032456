import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styled, {keyframes} from 'styled-components';
import { t } from 'ttag';
import useOrderContext from '../../../../../contexts/order/useOrderContext';
import {rgba} from "polished";
import {Theme} from "../../../../../contexts/operator/operator-context";
import {money} from "../../../../../services/format";
interface TipAddBasketProps {
    id: string;
    label?: string;
    tipValue: number;
    disabled: boolean;
    defaultChecked?: boolean;
    selected: boolean;
    isConfirmationPage: boolean;
}

const TipAddBasket = ({
    id,
    label = t`Add a tip`,
    tipValue,
    disabled = false,
    defaultChecked = false,
    selected,
    isConfirmationPage = false
}: TipAddBasketProps) => {

    const orderContext = useOrderContext();

    useEffect(() => {
        orderContext.setServiceSelected(defaultChecked);
    }, [defaultChecked]);

    const handleCheckboxClick = () => {
        orderContext.setServiceSelected(!selected);
    }

    const [customTip, setCustomTip] = useState('');
    const [tipIndex, setTipIndex] = useState(0);
    const [customSelected, setCustomSelected] = useState(false);

    const handleBlur = (e:any) => {
        let num = parseFloat(e.target.value);
        let cleanNum = num.toFixed(2);
        setCustomTip(cleanNum);
    }

    const handleFocus = () => {

    }

    const renderCustomTip = () => {
        return (
            <StyledTipInput active={customSelected}>
                <Form.Control
                    type="number"
                    name="customTip"
                    value={customTip}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    ref={null}
                    onChange={(e:any) => setCustomTip(e.target.value)}
                    // isValid={}
                    // isInvalid={}
                    placeholder={t`Enter tip`}
                    disabled={orderContext.loading}
                />
                <div className={'custom-tip__currency'}>£</div>
            </StyledTipInput>

        )
    }

    const selectTip = (index: number) => {
        setTipIndex(index);
        setCustomSelected(false);
        // setCustomTip(tip);
    }

    return (
        <>
            <Form.Group controlId={id} className={'mb-0'}>
                <Form.Label className={`d-block mb-0`}>
                    <Layout cursorNormal={ isConfirmationPage || tipValue === 0 || disabled }>
                        {/*{ !disabled && (<StyledCheckbox>*/}
                            {/*<Form.Check type={'checkbox'} id={id} onClick={handleCheckboxClick} disabled={disabled} />*/}
                        {/*    <CheckboxIcon*/}
                        {/*        selected={selected}*/}
                        {/*        className={selected ? 'anim--modifier-pulse' : ''}*/}
                        {/*    >*/}
                        {/*        {selected && (*/}
                        {/*            <svg*/}
                        {/*                className={'text-white'}*/}
                        {/*                width={14}*/}
                        {/*                height={14}*/}
                        {/*                fill="none"*/}
                        {/*                xmlns="http://www.w3.org/2000/svg"*/}
                        {/*            >*/}
                        {/*                <path*/}
                        {/*                    d="M2.293 7.707l3.774 3.774 5.702-6.841-1.537-1.28-4.299 5.159-2.226-2.226-1.414 1.414z"*/}
                        {/*                    fill="currentColor"*/}
                        {/*                />*/}
                        {/*            </svg> )}*/}
                        {/*    </CheckboxIcon>*/}
                        {/*</StyledCheckbox>)}*/}
                        <span className="checkbox-label">{label}</span>
                        <span className="checkbox-price">{money(tipValue)}</span>
                    </Layout>
                </Form.Label>
            </Form.Group>
            <StyledTipSelector>
                <StyledTipButton selected={tipIndex === 0 && !customSelected} onClick={() => selectTip(0)}>{t`No tip`}</StyledTipButton>
                <StyledTipButton selected={tipIndex === 1 && !customSelected} onClick={() => selectTip(1)}>{t`10%`}</StyledTipButton>
                <StyledTipButton selected={tipIndex === 2 && !customSelected} onClick={() => selectTip(2)}>{t`12.5%`}</StyledTipButton>
                <StyledTipButton selected={tipIndex === 3 && !customSelected} onClick={() => selectTip(3)}>{t`15%`}</StyledTipButton>

            </StyledTipSelector>
            { customSelected ? renderCustomTip() :  (<StyledCustomTipButton onClick={() => setCustomSelected(true)}>Custom tip</StyledCustomTipButton>) }
        </>
    )
};

const StyledCustomTipButton = styled.button`
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 15px;
    line-height: 24px;
    color: var(--brand);
    &:hover {
        text-decoration: underline;
    }
`;

const StyledTipButton = styled.button<{selected: boolean}>`
    position: relative;
    flex-grow: 1;
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px var(--border-input);
    background-color: transparent;
    padding: 9px 16px;
    color: var(--brand);
    font-family: var(--text_ui_font);
    transition: background-color 0.2s ease-in-out;
    margin-right: 1px;
    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    ${(props) => props.selected ? `
        border-color: var(--brand); 
        box-shadow: 0 0 0 1px var(--brand);
        z-index: 5; 
        background-color: var(--brand-10);
    ` : `
        &:hover {
            background-color: var(--bg-grey);
        }
    `}
    
`;

const StyledTipSelector = styled.div`
    display: flex;
    margin-bottom: 12px;
`;

const StyledTipInput = styled.div<{active: boolean}>`
    position: relative;
    .form-control {
        padding-left: calc(48px + 12px);
        ${(props) => props.active ? 'border-color: var(--brand);' : ''}
        
        &:hover ~ .custom-tip__currency { 
            border-color: var(--border-input-hover);
        }
        &:focus ~ .custom-tip__currency {
            border-color: var(--brand);
            box-shadow: 1px 0 var(--brand);
        }
    }
    .custom-tip__currency {
        position: absolute;
        top: 0;
        left: 0:
        height: 48px;
        width: 48px;
        line-height: 48px;
        font-size: 15px;
        text-align: center;
        font-weight: 500;
        border-right: 1px solid var(--brand);
        background-color: var(--brand-10);
        color: var(--brand);
        font-family: var(--text_ui_font);
        text-transform: var(--text_ui_case);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        transition: box-shadow 0.15s ease-in-out, border-color 0.15s ease-in-out;
    }
`;

const Layout = styled.div<{ cursorNormal: boolean }>`
    display: flex;
    align-items: center;
    cursor: ${(props) => props.cursorNormal ? 'normal' : 'pointer' };
    margin-bottom: 12px;
    .checkbox-label {
        user-select: none;
        flex: 1;
        font-size: 15px;
    }

    .checkbox-label,
    .checkbox-price {
        font-size: 15px;
        color: var(--text-black);
       
        @media screen and (max-width: 576px) {
            font-size: 15px;
        }
    }
`;


export default TipAddBasket;
