import React, {useContext, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { t } from 'ttag';
import info from '../../../assets/images/info-black.svg';
import useOrderContext from '../../../contexts/order/useOrderContext';
import useSiteContext from '../../../contexts/site/useSiteContext';
import PageSpinnerThree from '../../general/PageSpinnerThree';
import AppLayout from '../../layouts/app/AppLayout';
import ScrollToTop from '../../../hooks/scrollToTop';
import EmailSignup from './components/EmailSignup';
import Basket from "../Menu/components/basket/Basket";
import useBasketContext from "../../../contexts/basket/useBasketContext";
import {useNavigate} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import {Accordion, AccordionCollapse, AccordionContext, useAccordionToggle} from "react-bootstrap";
import {money} from "../../../services/format";
import visa from "../../../assets/images/visa.svg";
import mastercard from "../../../assets/images/mastercard.svg";
import amex from "../../../assets/images/amex.svg";
import card_icon from "../../../assets/images/feather/credit-card.svg";
import BasketItem from "../Menu/components/basket/BasketItem";
import useOperatorContext from "../../../contexts/operator/useOperatorContext";
import {getDisplayName} from "../../../services/channel";
import powered_by from "../../../assets/images/logo-powered-by.svg";
import moment from "moment/moment";

const ConfirmationPage = () => {
    const navigate = useNavigate();
    const siteContext = useSiteContext();
    const orderContext = useOrderContext();
    const operatorContext = useOperatorContext();
    const basketContext = useBasketContext();

    // useEffect(() => {
    //     if (orderContext.order === null) {
    //         navigate('/');
    //     }
    // });

    useEffect(() => {
        mixpanel.track('viewed confirmation page');
    }, []);

    if (!orderContext || !orderContext.order) {
        return <PageSpinnerThree show={true} />;
    }

    if (orderContext.loading) {
        return <PageSpinnerThree show={true} />;
    }

    //CONFIRMATION

    interface LineItemProps {
        text: string,
        value: number,
        bold?: boolean
    }

    function CustomToggle({ children, eventKey, callback }: any) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <button
                type="button"
                className={`customToggle ${isCurrentEventKey ? 'show' : ''}`}
                onClick={decoratedOnClick}
            >
                {children}
                <i className="feather feather-chevron-down feather-20 brand-color-filter"/>
            </button>
        );
    }

    const AsapLabel = (input: moment.MomentInput) => {
        const inputDateTime = moment(input, "YYYY-MM-DD HH:mm");
        const currentDate = moment();

        // Check if the input date is today
        if (inputDateTime.isSame(currentDate, 'day')) {
            // If it's today, format the time as "Today HH:mm"
            return "Today " + inputDateTime.format("HH:mm");
        } else {
            return inputDateTime.format("DD MM HH:mm");
        }
    }

    const LineItem = ({text, value, bold}: LineItemProps) => {
        return (
            <div className={'conf__receipt-item' + (bold ? ' conf__receipt-item--bold' : '')}>
                <span className={'conf__receipt-item__name'}>{text}</span>
                <span className={'conf__receipt-item__value'}>{money(value)}</span>
            </div>
        )
    }

    const paymentDetails = () => {
        return {
            // last4: orderContext.storedOrder?.payments.last4 ?? '',
            // brand: orderContext.storedOrder?.payments.type ?? ''
            last4: orderContext.storedOrder?.payments.filter((pa) => pa.type !== 'giftcard')[0].last4 ?? '',
            brand: orderContext.storedOrder?.payments.filter((pa) => pa.type !== 'giftcard')[0].type ?? ''
        }
    }


    const getCardImage = (card:String) => {
        switch (card) {
            case 'visa':
                return visa;
            case 'mastercard':
                return mastercard;
            case 'amex':
                return amex;
            default:
                return card_icon
        }
    }

    const service = () => {
       return orderContext.storedOrder?.service ?? 0;
    }

    const subtotal = () => {
       return orderContext.storedOrder?.subtotal ?? 0;
    }

    const total = () => {
        return orderContext.storedOrder?.total ?? 0;
    }

    const orderItems = () => {
        if (orderContext.storedOrder?.basket) {
            return orderContext.storedOrder?.basket;
        }
        return [];
    }

    const getFirstName = () => {

        if (orderContext.order?.orderData) {
            if (typeof orderContext.order.orderData.customer === 'string'){
               return JSON.parse(orderContext.order.orderData.customer).first_name
            } else if (typeof orderContext.order?.orderData.customer === 'object') {
                return orderContext.order.orderData.customer.first_name
            }
        }
        return null;
    }

    const renderPromoDiscountLine = () => {
        let text = '';
        let discount = 0;


        text = `(${orderContext.storedOrder?.discount?.description})`;
        discount = orderContext.storedOrder?.discount?.amount ?? 0;

        if (orderContext.storedOrder?.payments?.filter((pa) => pa.type === 'giftcard')[0] ) {
            text = 'Giftcard';
            discount = orderContext.storedOrder?.payments?.filter((pa) => pa.type === 'giftcard')[0].amount;
        }

        if (basketContext.validatedBasket?.codes.length) {

            text = basketContext.validatedBasket?.codes[0].type === 'promo'
                ? 'Discount ' : 'Giftcard ';

            text += `(${basketContext.validatedBasket?.codes[0].reference})`;

            discount = basketContext.validatedBasket?.promo_discount ?? 0;
        }

        if ( discount === 0 ) { return null; }

        return (
            <LineItem text={"Discount " + text} value={-discount}/>
        )
    }

    return (
        <AppLayout page="confirmation">

            <ScrollToTop />
            <Helmet>
                <title>{t`Confirming your order - Powered by orderswift`}</title>
            </Helmet>
            <StyledContainer>
                {/*<StyledRow>*/}
                {/*    <StyledThanks>*/}
                {/*        <h2>{t`Thank you`}, {orderContext.order.customer_name}</h2>*/}
                {/*        <div className="conf-details d-flex align-items-center" style={{margin: '8px 0 24px 0'}}>*/}
                {/*            <span>{t`Your Order`}: {orderContext.order.ref}</span>*/}
                {/*        </div>*/}
                {/*        {siteContext.site?.show_instructions_at_confirm && siteContext.site?.instructions ? (*/}
                {/*            <Instructions>*/}
                {/*                <i className="feather feather-info" />*/}
                {/*                <span>{ siteContext.site?.instructions }</span>*/}
                {/*            </Instructions>*/}
                {/*        ) : null}*/}
                {/*        <EmailSignup />*/}
                {/*    </StyledThanks>*/}
                {/*    <StyledOrderDetails>*/}
                {/*        <Basket*/}
                {/*            checkout={true}*/}
                {/*            reset={false}*/}
                {/*            site={basketContext.validatedSite}*/}
                {/*            confirmation={true}*/}
                {/*        />*/}
                {/*    </StyledOrderDetails>*/}
                {/*</StyledRow>*/}
                <StyledRow>
                    <div className={'conf__pane'}>
                        <h1 className={'conf__thanks'}>{t`Thank you`}{getFirstName() ? (<>,<br/>{getFirstName()}!</>) : null}</h1>
                        <StyledCard border>
                            <StyledBlock>
                                {/*<h2>{t`Thank you`}, {orderContext.order.customer_name}</h2>*/}
                                <div>
                                    {/*<span>{t`Your Order`}: {orderContext.order.ref}</span>*/}
                                    <h4 className={'conf__heading'}>{t`Order`} #{orderContext.order.ref}</h4>
                                </div>

                                <div className={'conf__info'}>
                                    <span className="conf__info__channel">{getDisplayName(siteContext.channel)}</span>
                                    <span className="conf__info__site_name">{operatorContext.operator?.name}, {siteContext.site?.name}</span>
                                    <span className="conf__info__timeslot">{
                                        orderContext.storedOrder?.orderData?.asap
                                        ? AsapLabel(orderContext.storedOrder?.ready_time?.id)
                                        : orderContext.storedOrder?.ready_time?.name
                                    }</span>
                                </div>

                                {/*<EmailSignup />*/}
                            </StyledBlock>
                            {/*{siteContext.site?.show_instructions_at_confirm && siteContext.site?.instructions ? (*/}
                            {/*    <Instructions>*/}
                            {/*        <i className="feather feather-info" />*/}
                            {/*        <span>{ siteContext.site?.instructions }</span>*/}
                            {/*    </Instructions>*/}
                            {/*) : null}*/}


                        </StyledCard>
                        {orderContext.order.site?.show_instructions_at_confirm && orderContext.order.site?.instructions ? (
                            <StyledCard>
                                <StyledBlock bg_var={"brand-10"} className={'conf__note'}>
                                    <div className={'conf__note__header'}><i className="feather feather-info" /><span>{t`Restaurant note`}</span></div>
                                    <span>{ orderContext.order.site?.instructions } </span>
                                </StyledBlock>
                            </StyledCard>
                        ) : null}

                    </div>


                    <div className={'conf__pane conf__pane--end'}>
                        <StyledCard>
                            <div className={'conf__map'}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.0225781548984!2d-0.13428892304460124!3d51.51280177181453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604d2fb7ef421%3A0x4c6c0b15e2f0a414!2sRosa&#39;s%20Thai%20Soho!5e0!3m2!1sen!2suk!4v1702297500421!5m2!1sen!2suk"
                                    // src={"https://www.google.com/maps/embed/v1/place?q=" + encodeURIComponent(orderContext.order.site?.address_1 + ', ' + orderContext.order.site?.address_city + ', ' + orderContext.order.site?.address_postcode)}
                                    // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15282225.79979123!2d73.7250245393691!3d20.750301298393563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1587818542745!5m2!1sen!2sin"
                                    width="100%" height="150" frameBorder="0" style={{border: 0}} allowFullScreen={false}
                                    aria-hidden="false" tabIndex={0}></iframe>

                            </div>
                            <StyledBlock className={'conf__map-content'}>
                                <h4 className={'conf__heading'}>{operatorContext.operator?.name} - {siteContext.site?.name}</h4>
                                <div className={'conf__site-detail'}>
                                    {/*<i className={'conf__site-detail__icon feather feather-map-pin feather-20'}/>*/}
                                    <div className={'conf__site-detail__text'}>
                                        <span>{orderContext.order.site?.address_1}, {orderContext.order.site?.address_city} {orderContext.order.site?.address_postcode}</span>
                                        <a href={'#'} className={'conf__site-detail__link'}>Get directions</a>
                                    </div>
                                </div>
                                <div className={'conf__site-detail'}>
                                    {/*<i className={'conf__site-detail__icon feather feather-phone feather-20'}/>*/}
                                    <div className={'conf__site-detail__text'}>
                                        <a href={'tel:'+ orderContext.order.site?.phone}>{orderContext.order.site?.phone}</a>
                                    </div>
                                </div>
                            </StyledBlock>
                        </StyledCard>

                        <StyledCard border mobileBorderTop>
                            <StyledBlock noMobilePadding={true}>
                                <h4 className={'conf__heading conf__heading--order'}>{t`Order summary`}</h4>
                                <StyledAccordion defaultActiveKey="-1">
                                    <CustomToggle eventKey="0">
                                        <div className="toggle">
                                            {/*<span className="toggle__title">Order summary</span>*/}
                                            <span>
                                                {orderItems().length} item{orderItems().length > 1 ? 's' : ''}
                                            </span>
                                            {/*<span className="toggle__price">*/}
                                            {/*    {money(total())}*/}
                                            {/*</span>*/}
                                        </div>
                                    </CustomToggle>

                                    <AccordionCollapse eventKey="0">
                                        <>
                                            {orderItems().map((item, index) => (
                                                <BasketItem
                                                    key={`basket-${index}-${item.uid}`}
                                                    handleClick={() => {}}
                                                    item={item}
                                                    checkout={true}
                                                    last={index === orderItems().length - 1}
                                                />
                                            ))}
                                        </>
                                    </AccordionCollapse>
                                </StyledAccordion>
                                <div className={'conf__receipt-items'}>
                                    <LineItem text={'Subtotal'} value={subtotal()}/>
                                    {
                                        (orderContext.storedOrder?.service ?? 0) === 0
                                        ? null
                                        : <LineItem text={'Service'} value={service()}/>
                                    }
                                    {renderPromoDiscountLine()}
                                    <LineItem bold text={'Total'} value={total()}/>
                                </div>
                                {/*Only show if total > 0*/}
                                {total() > 0 ? (
                                    <PaymentCardDetails className={'basket__payment-info'}>
                                        <div className={"basket__payment-info__text"}>
                                            Paid with
                                        </div>
                                        <div className={"basket__payment-info__card"}>
                                            <img width={'32'} height={'24'} src={getCardImage(paymentDetails().brand)}/>
                                            <span className={''}>••••{paymentDetails().last4}</span>
                                        </div>
                                    </PaymentCardDetails>
                                ) : null}

                            </StyledBlock>

                        </StyledCard>
                    </div>
                </StyledRow>
                <div className='orderswift-banner-confirmation'>
                    <img width={188} height={28} src={powered_by} alt={'Powered by Orderswift'} />
                </div>
            </StyledContainer>
        </AppLayout>
    );
};



const StyledContainer = styled.div`
    max-width: 1092px;
    padding: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    
    .orderswift-banner-confirmation {
        z-index: 999;
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        left: 0;
        background: white;
        box-shadow: 0 -1px 0 var(--border-grey);
        padding: 2px 0;
    }
    
    .conf {
        &__thanks {
            padding: 32px 20px 12px 20px;
            margin-bottom: 0;
            @media screen and (min-width: 993px) {
                padding: 0;
                margin-bottom: 32px;
            }
        }
        &__note {
            color: var(--brand);
            font-size: 16px;
            line-height: 24px;
            @media screen and (max-width: 992px) {
                border-bottom: 1px solid var(--border-grey);
            }
            &__header {
                display: flex;
                gap: 8px;
                margin-bottom: 12px;
                i {line-height: 24px;}
            }
        }
        &__heading {
            margin-bottom: 16px;
            &--order {
                padding-top: 0;
                @media screen and (max-width: 992px) {
                    border-bottom: 1px solid var(--border-grey);
                    padding: 20px;
                    margin-bottom: 0;
                }
            }
        }
        &__map {
            @media screen and (min-width: 993px){
                margin-left: -1px;
                margin-top: -1px;
                margin-right: -1px;
                height: 150px;
                &-content {
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-width: 0 1px 1px 1px;
                    border-style: solid;
                    border-color: var(--border-grey);
                }
            }
        }
        &__info {
            display: flex;
            flex-direction: column;
            gap: 8px;
            
            &__channel, &__site_name, &__timeslot {
                font-size: 16px;
                line-height: 24px;
            }
            &__channel {
                font-weight: 500;
            }   
        }
        &__pane {
            width: 100%;
            &--end {
                padding-bottom: 32px;
            }
            @media screen and (min-width: 993px) {
                padding: 48px 32px;
                max-width: 546px;
                border-color: var(--border-grey);               
            }
        }
        &__receipt-items {
            padding: 12px 0;
            @media screen and (min-width: 993px){
                border-top: 1px solid var(--border-grey);
            }
        }
        &__receipt-item {
            display: flex;
            gap: 12px;
            padding: 4px 20px;
            font-size: 16px;
            line-height: 24px;        
            &__name {
                flex-grow: 1;
            }
            @media screen and (min-width: 993px){
                padding: 4px 0;
            }
            &--bold {
                font-weight: 600;
            }
        }
        &__site-detail {
            display: flex;
            gap: 12px;
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
            &__icon {
                height: 20px;
                width: 20px;
                flex-shrink: 0;
                color: var(--text-grey);
            }
            &__text {
                flex-grow: 1;
                span {
                    display: block;
                    margin-bottom: 8px;
                }
                a {
                    color: var(--brand);
                }
            }
        }
    }
`;

const StyledRow = styled.div`
    @media screen and (min-width: 993px) {
        display: flex;
    }


    .basket__order-info {
        .privacy-notice {
            font-size: 12px;
            font-weight: 400;
            color: var(--text-grey);

            padding-bottom: 0 !important;

            a {
                color: var(--text-grey);
                text-decoration: underline;
                font-weight: 500;
            }
        }
        

        &--desktop {
            display: none;
            @media screen and (min-width: 993px) {
                display: block;
            }
        }
        &--mobile {
            @media screen and (min-width: 993px) {
                display: none;
            }
        }
       
    }
    .only-show-mobile {
        @media screen and (min-width: 993px) {
            display: none;
        }
    }
    .only-show-desktop {
        display: none;
        @media screen and (min-width: 993px) {
            display: block;
        }
    }s

`;
const StyledOrderDetails = styled.div`
    align-self: flex-start;
    width: 100%;
    border-top: 1px solid var(--border-grey);
    @media screen and (min-width: 993px) {
        max-width: 432px;
        border: 1px solid var(--border-grey);
        border-radius: 8px;
        padding: 20px 0;
        position: sticky;
        top: 128px;
    }
`;
const StyledThanks = styled.div`
    width: 100%;
    padding: 20px;
    @media screen and (min-width: 993px) {
        padding-top: 0;
    }
`;

const Notice = styled.div`
    border-radius: 4px;
    padding: 12px;
    font-size: 14px;
    margin-bottom: 32px;
    img {
        margin-right: 10px;
    }
    h6 {
        font-size: 16px;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        font-family: ${(props) => props.theme.bodyFont.name};
        text-transform: none;
        color: var(--text-black);
        font-weight: 600;
    }

    @media screen and (max-width: 576px) {
        font-size: 12px;
        h6 {
            font-size: 14px;
        }
    }
`;


// CONFIRMATION
const StyledBlock = styled.div<{bg_var?: string, borderTop?: boolean, noMobilePadding?: boolean}>`
    width: 100%;
    ${(props) => props.noMobilePadding ? null : "padding: 20px;"}
    background-color: var(--${(props) => props.bg_var ?? 'white'});
    ${(props) => props.borderTop ? 'border-top: 1px solid var(--border-grey);' : null}
    @media screen and (min-width: 993px) {
        padding: 20px;
    }
`;


const Instructions = styled.div`
    padding: 18px;
    margin-bottom: 24px;
    border-radius: 4px;
    
    background-color: var(--bg-grey);
    color: var(--text-black);
    i {
        margin-right: 12px;
    }
`;


const StyledCard = styled.div<{mobileBorderTop?: boolean, border?: boolean}>`  
    ${(props) => props.mobileBorderTop ? 'border-top: 1px solid var(--border-grey);' : ''}
    @media screen and (min-width: 993px){
        ${(props) => props.border ? 'border: 1px solid var(--border-grey);' : ''}
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 32px;
    }
`;
const PaymentCardDetails = styled.div`
    
    &.basket__payment-info {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 20px;
        font-size: 16px;
        font-weight: 500;
        border-top: 1px solid var(--border-grey);
        @media screen and (min-width: 993px){
            padding: 20px 0 0 0;
        }
    }
    .basket__payment-info {
        &__text {
            flex-grow: 1;
        }
        &__card {
            display: flex;
            align-items: center;
            gap: 16px;
           
        }
    }
    
`;
const StyledAccordion = styled(Accordion)`
    .customToggle {
        transition: background-color 0.15s ease-in-out;
        i { 
            transition: transform 0.15s ease-in-out;
        }
        &.show {
            i {
                transform: rotate(180deg);
            }
        }
        @media screen and (min-width: 993px) {
            // display: none;
        }
    }
    .promoSubmit {
        height: 48px;
        margin: 0;
        padding: 0 23px;
        margin-left: 16px;

        @media screen and (min-width: 768px) {
            padding: 0 40px;
            min-width: 152px;
            margin-left: 24px;
        }
    }
    .collapse, .collapsing {
        padding: 12px 0;
        border-bottom: 1px solid var(--border-grey);
        @media screen and (min-width: 993px) {
            border-bottom: 0;
            // display: block;
            // border-top: 1px solid var(--border-grey);
        }
        &.show {
            
        }
    }
    > button {
        width: 100%;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        display: flex;
        padding: 20px;
        border-bottom: 1px solid var(--border-grey);
        @media screen and (min-width: 993px) {
            padding: 20px 0;
            border-bottom-width: 0;
            border-top: 1px solid var(--border-grey);
            &.show {
                border-bottom-width: 1px;
            }
        }
        margin: 0;
        font-weight: 600;
        align-items: center;
        color: var(--text-black);
        text-align: left;

        img {
            transition: transform 0.2s linear;
        }

        &.show {
            img {
                transform: rotate(180deg);
            }
        }

        &.show {
            .toggle {
            }
        }

        .toggle {
            width: 100%;
            display: flex;
            /* margin-left: 16px; */
            color: ${(props) => props.theme.shade_0};
            flex-wrap: wrap;
            flex-grow: 1;
            > span {
                margin-right: 8px;
                color: var(--brand);
            }
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;

            &__title {
                font-weight: 500;
            }
            &__price {
                flex-grow: 1;
                text-align: right;
            }

            @media screen and (min-width: 768px) {
                /* margin-left: 24px; */
            }
        }
    }
`;

export default ConfirmationPage;
