export function setCspContent () {

    const csp = {

        'default-src': [ "'self'" ],

        'img-src': [
            "'self'",
            'data:',
            'https://s3.eu-west-2.amazonaws.com/' + process.env.REACT_APP_AWS_BUCKET + '/',
            'https://s3.eu-west-2.amazonaws.com/orderswift-production/',
            'https://www.google-analytics.com',

            // 'https://s3.eu-west-2.amazonaws.com/orderswift-dev/',
            // 'https://s3.eu-west-2.amazonaws.com/orderswift-staging/',
            // 'https://s3.eu-west-2.amazonaws.com/orderswift-production/',
            // 'https://maps.googleapis.com',
            // 'https://maps.gstatic.com',
            // 'https://resizer.staging.deliverect.com',
            // 'https://resizer.deliverect.com',
            'https://www.facebook.com',
            // 'https://www.google-analytics.com'
        ],


        'style-src': [
            "'self'",
            (process.env.REACT_APP_ENV === 'local' ? "'unsafe-inline'" : ''), // Allow inline styles

            // 'https://s3.eu-west-2.amazonaws.com/' + process.env.REACT_APP_AWS_BUCKET + '/',
            // 'https://s3.eu-west-2.amazonaws.com/orderswift-production/',

            'https://fonts.googleapis.com', // Allow stylesheets from Google Fonts
            'https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css'
        ],

        'font-src': [
            "'self'",
            'data:',
            'https://fonts.gstatic.com', // Allow fonts from the Google Fonts CDN
            'https://s3.eu-west-2.amazonaws.com/' + process.env.REACT_APP_AWS_BUCKET + '/',
            'https://s3.eu-west-2.amazonaws.com/orderswift-production/',
            'https://unpkg.com/boxicons@2.1.2/fonts/'
        ],


        'script-src': [
            "'self'",
            "'unsafe-eval'",
            "'unsafe-inline'",
            'https://www.google-analytics.com',
            'https://js.stripe.com',
            'https://connect.facebook.net',
            'https://www.googletagmanager.com',

            // 'https://maps.googleapis.com',

            // 'https://d2wy8f7a9ursnm.cloudfront.net',

        ],

        'connect-src': [
            "'self'",
            'https://sessions.bugsnag.com',
            'https://www.google-analytics.com',
            'https://region1.google-analytics.com',
            'https://region1.analytics.google.com',
            process.env.REACT_APP_API_URL,

            'https://api-js.mixpanel.com',
            'https://notify.bugsnag.com',
            'https://api.addressy.com'
            // 'https://orderswift.cloudflareaccess.com',
            // 'https://maps.googleapis.com',
            // 'https://stats.g.doubleclick.net'
        ],

        'frame-src': [
            'https://js.stripe.com',
            'https://www.google.com'
            // 'https://www.googletagmanager.com',
        ]


    };

    let str = '';

    Object.entries(csp).forEach(([key, value]) => {

        str += key + ' ' + value.join(' ') + '; ';

    });

    return str;
}