import React from 'react';
import styled from 'styled-components';
import { Category, Item } from '../../../../contexts/site/site-context';
import MenuItem from './items/MenuItem';
import {Preferences} from "../MenuPage";
import useOperatorContext from "../../../../contexts/operator/useOperatorContext";


type MenuCategoryProps = {
    category: Category;
    showItemModal: (item: Item, edit: boolean) => void;
    allowItemImages: boolean;
    menuItemStyle?: string;
    isFirst?: boolean;
    preferences: Preferences;
};

const MenuCategory = ({ category, showItemModal, allowItemImages, menuItemStyle = 'classic' , isFirst = false, preferences }: MenuCategoryProps) => {

    const operatorContext = useOperatorContext();

    const renderMenuItems = () => {
        return category.items.map((item, index) =>
            <MenuItem
                key={`mcmi-${item.uid}`}
                item={item}
                showItemModal={showItemModal}
                allowItemImages={allowItemImages}
                preferences={preferences}
                variant={menuItemStyle}
            />
        );
    };

    const renderCategoryTimeRestrictions = () => {
        if ( !operatorContext.operator?.has_timed_categories ) {
            return null;
        }

        return (
            <p className={'menu-category__timings'}>
                <i className={'feather feather-clock feather-16'}></i>
                <span>Available Monday - Friday, 12-4pm</span>
            </p>
        );
    }

    if (category.is_hidden) {
        return null;
    }

    const visibleItems = category.items.filter((i) => {

        if ( i.is_vegan && !preferences.vegan ) { return false }
        if ( i.is_vegetarian && !preferences.vegetarian ) { return false }
        if ( i.is_gluten_free && !preferences.glutenFree ) { return false }

        return true;

    }).length;

    if ( visibleItems === 0 ) {
        return null;
    }

    return (
        <>
            <StyledCategory id={(category.uid as unknown) as string} className="menu-category">
                <CategoryHeader className="menu-category__header">
                    <div className='d-flex align-item-center justify-content-between'>
                        <h3>{category.name}</h3>
                    </div>
                    {renderCategoryTimeRestrictions()}
                    <p className={`description ${isFirst ? 'first' : ''}`}>
                        {category.description}
                    </p>
                </CategoryHeader>
                <CategoryItems className="menu-category__content row">
                    {renderMenuItems()}
                </CategoryItems>
            </StyledCategory>
        </>
    );
};
const StyledCategory = styled.div`
    scroll-margin-top: 72px;
    border-radius: 20px;
    overflow: visible;
    position: relative;
    @media screen and (min-width: 768px) {
        overflow: initial;
        scroll-margin-top: 148px;
    }
    &:nth-child(even) {
        .menu-category__timings { display: none;}
    }
    
     &:nth-child(odd) .menu-category__timings {
        display: flex;
        align-items: center;
        gap: 6px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0;
        margin-top: 8px;
        margin-bottom: 0;
        color: var(--brand);
        @media screen and (min-width: 768px) {
            // font-size: 16px;
            // line-height: 24px;
            // margin-top: 0;  
            font-weight: 500;
        }
    }

    @media screen and (max-width: 576px) {

        /* h5 {
            margin-bottom: 1.5rem;
        } */
    }

    hr {
        position: relative;
        left: -16px;
        width: 100vw;
    }
    /* margin-bottom: 4px; */
`;

const CategoryHeader = styled.div`
    padding: 20px;

    hr {
        border-color: ${(props) => props.theme.color_border};
    }

    h3 {
        margin-bottom: 0;
    }

    .description {
        color: var(--text-black);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 8px;
        margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
        padding: 0 48px;
        margin-bottom: 24px;

        h3 {
            padding-top: 0 !important;
        }
    }
`;
const CategoryItems = styled.div`
    display: grid;
    margin: 0;
    margin-bottom: 48px;
    
    ${(props) => props.theme.v3.ux.menu_layout === 'classic' && `
        
        grid-template-columns: repeat(1, minmax(0, 1fr));
        
        @media screen and (min-width: 993px) { 
            grid-template-columns: repeat(2, minmax(0, 1fr))
        }
        @media screen and (min-width: 768px) {
            padding: 0 48px;
            gap: 24px;
        }
    `}
    
    ${(props) => props.theme.v3.ux.menu_layout === 'portrait' && `
    
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
        padding: 0 20px;
        
        @media screen and (min-width: 993px) { 
            grid-template-columns: repeat(auto-fill, minmax(280px, 1fr))
        }
        
        @media screen and (min-width: 768px) {
            padding: 0 48px;
            gap: 24px;
        }
    `}


    /*@media screen and (min-width: 768px) {
        & > div:nth-child(odd) {
            padding-right: 12px;
        }
        & > div:nth-child(even) {
            padding-left: 12px;
        }
    }*/
`;

export default MenuCategory;