import { PaymentMethod, Stripe } from '@stripe/stripe-js';
import { createContext } from 'react';
import {BasketItem, ReadyTime, TBasket} from '../basket/basket-context';
import { CustomerFormObject } from '../customer/customer-context';
import { TChannel } from '../operator/operator-context';
import { Timeslot, TSite } from '../site/site-context';
import basketItem from "../../components/pages/Menu/components/basket/BasketItem";

export interface TOrder {
    result: boolean;
    message: string;
    ref: string;
    subtotal: number;
    discount: Discount;
    service: number;
    total: number;
    paid: string;
    delivery_fee: number;
    delivery_window: string;
    basket: BasketItem[];
    site?: TSite;
    orderData?: OrderData;
    ready_time?: ReadyTime;
    table?: string;
    //payments: Payment;
    payments: Payment[];
    customer_email: string;
    customer_name: string;
}

export interface Payment {
    type: string;
    amount: number;
    last4?: string;
}

export interface Discount {
    description: string;
    amount: number;
}

export interface OrderData {
    customer:
        | {
              email: string;
              first_name: string;
              last_name: string;
              tel: string;
              address_1: string;
              address_city: string;
              address_postcode: string;
              address_country: string;
              billing_postcode: string;
              marketing_opt_in: boolean;
              allow_orderswift_emails: boolean;
          }
        | string;
    site_id: string;
    channel: TChannel;
    basket_id: string;
    time: string;
    asap: boolean;
    payment_intent?: { payment_intent: string };
    payment_method?: { payment_method: PaymentMethod | undefined };
    special_notes?: string;
    table_number?: string;
    cover_count?: number;
    promo_code?: string;
    is_service_selected?: boolean;
    flow?: string;
    is_secure_payment?: boolean;
}

export interface TError {
    field: string;
    messages: any[];
}

export type OrderContextData = {
    order: TOrder | null;
    setOrder: (order: TOrder | null) => void;

    storedOrder: TOrder | null;
    setStoredOrder: (order: TOrder | null) => void;

    orderData: OrderData | null;
    setOrderData: (order: OrderData) => void;

    getOrderData: (customerFormData: CustomerFormObject, paymentMethod?: PaymentMethod) => any;
    processOrder: (order: OrderData, stripe?: Stripe) => void;
    signUpCustomer: () => Promise<any> | undefined;

    loading: boolean;
    setLoading: (state: boolean) => void;

    // timeslot: Timeslot | null;
    // setTimeslot: (timeslot: Timeslot) => void;

    serviceSelected: boolean;
    setServiceSelected: (selected: boolean) => void;
    total: number;

};

export const OrderContext = createContext<OrderContextData>({
    order: null,
    setOrder: () => {},

    storedOrder: null,
    setStoredOrder: () => {},

    orderData: null,
    setOrderData: () => {},

    getOrderData: () => {},
    processOrder: () => {},
    signUpCustomer: () => new Promise<any>((resolve, reject) => {}),

    loading: false,
    setLoading: () => {},

    // timeslot: null,
    // setTimeslot: () => {},

    serviceSelected: false,
    setServiceSelected: () => {},
    total: 0,
});
