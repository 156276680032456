import React, { useEffect, useState} from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import {CTA } from "../../general/Button";
import { Preferences } from "./MenuPage";
import mixpanel from "mixpanel-browser";

type PreferencesModalProps = {
    show: boolean;
    onHide: () => void;
    originalPreferences: Preferences;
    updatePreferences: (preferences: Preferences) => void;
};

const PreferencesModal = ({
    show,
    onHide,
    originalPreferences,
    updatePreferences,
}: PreferencesModalProps) => {

    const [preferences, setPreferences] = useState<Preferences>(originalPreferences);

    useEffect(() => {
        document.getElementById('preferences-modal')?.classList.add('anim-enter');

        if ( show ) {
            mixpanel.track('open preferences');
        }

        return () => {
            document.getElementById('preferences-modal')?.classList.remove('anim-enter');
        };
    }, [show]);

    const handleCheckboxClick = (id: string) => {
        let updatedPrefs = { ...preferences };
        updatedPrefs[id as keyof typeof preferences] = !preferences[id as keyof typeof preferences];
        setPreferences(updatedPrefs);
    };

    const savePrefs = () => {
        mixpanel.track('save preferences');
        updatePreferences(preferences);
        onHide();
    };

    const renderCheckbox = (id: string) => {
        return (
            <StyledCheckbox>
                <div className="switch-wrapper">
                    <div className="switch-row">
                        <label className="switch">
                           {/* <Form.Check type="checkbox" id={id}
                                checked={preferences[id as keyof typeof preferences]}
                            />*/}
                            <input type="checkbox"
                               name={id} 
                               id={id}
                               checked={preferences[id as keyof typeof preferences]}
                               onChange={ () => handleCheckboxClick(id) }
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            </StyledCheckbox>
        );
    }


    return (
        <PreferencesModalWrapper
            show={show}
            onHide={onHide}
            size="lg"
            backdrop={true}
            centered
            animation={false}
            id={'preferences-modal'}
        >
            <Modal.Header className="">
                <h4 className='modal__title'>Filters</h4>
                <div className="modal-close__wrapper">
                    <button role="button" className={'modal-close__btn'} onClick={() => onHide()}>
                        <i className="feather feather-x feather-18 brand-color-filter" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="preferences__list">
                    <div className="preference__header">Calories</div>
                    <div className="choice">
                        <span>Hide calories information</span>
                        <div>{renderCheckbox('calories')}</div>
                    </div>

                    {/*<div className="preference__header">Dietary preferences</div>
                    <div className="choice">
                        <span>Gluten-free</span>
                        <div>{renderCheckbox('glutenFree')}</div>
                    </div>
                    <div className="choice">
                        <span>Vegan</span>
                        <div>{renderCheckbox('vegan')}</div>
                    </div>
                    <div className="choice">
                        <span>Vegetarian</span>
                        <div>{renderCheckbox('vegetarian')}</div>
                    </div>*/}
                </div>
            </Modal.Body>
            <Modal.Footer className="">

                <div className="footerContent">
                    <CTA block onClick={savePrefs}>
                        <span>Update preferences</span>
                    </CTA>
                </div>
            </Modal.Footer>
        </PreferencesModalWrapper>
    );
};

const PreferencesModalWrapper = styled(Modal)`
    .modal-body {
        overflow-y: auto;

        .preferences__list {
            padding: 20px;
            margin-top: 65px;

            .preference__header {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: var(--text-black);
                padding-bottom: 20px;
            }

            .choice {
                display: flex;
                align-items: center;
                justify-content: space-between;

                padding-bottom: 20px;
                /* border-bottom: 1px solid var(--border-grey); */
                margin-bottom: 20px;


                span {
                    color: var(--placeholder);
                }

                &:hover { cursor: pointer; }
            }


        }

    }
    .modal-header {
        display: flex;
        align-items: center;
        > div { width: 100%}
        .modal__title {margin-bottom: 0;}
        background-color: transparent;
        z-index: 1;
        min-height: 64px;

        border-bottom: 1px solid var(--border-grey) !important;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 12px 8px 12px 20px;

        .modal__title {
            transition: opacity 0.2s ease-in-out;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(560px - 80px);
        }
    }

    .modal-body {
        padding: 0;
    }



    .close {
        float: left;
        opacity: 1;
    }



    .modal-footer {
        padding: 16px 20px !important;
        box-shadow: none !important;

        .footerContent {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;


        }

    }

`;

const StyledCheckbox = styled.span`
    
    .form-check {
        display: none;
    }
    
    /*=============================================
=                   SWITCHES                  =
=============================================*/

.switch-row {
    margin-bottom: 0px;
    p {
        display: inline-block;
    }
    .switch {
        float: right;
        margin-top: 0px;
    }
}

/* The switch - the box around the slider */

.switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 31px;
    margin: 0 10px;
}

.switch:last-child {
    margin-right: 0;
}

.switch:first-child {
    margin-left: 0;
}

/* Hide default HTML checkbox */

.switch input {
    display: none;
}

/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--border-grey);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 27px;
    width: 27px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
}

/*input:checked+.slider {
    background-color: #444;
}*/

input:checked+.slider {
    background-color: var(--brand);
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    transform: translateX(20px);
}

/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

`;
export default PreferencesModal;
