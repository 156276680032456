import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../../../contexts/basket/useBasketContext';
import { money } from '../../../../../services/format';
import {CTA, CTAProps} from '../../../../general/Button';


interface CartButtonProps {
    onClick: () => void;
    classes?: string;
    CTAProps?: CTAProps;
    label: string;
    type: string;
}

const CartButton = ({ onClick, classes, CTAProps, label, type }: CartButtonProps) => {
    const basketContext = useBasketContext();
    const [showAnim, setShowAnim] = useState(false);
    const [quantity, setQuantity] = useState(0);

    // Trigger animation on quantity change
    // useEffect(() => {
    //     setShowAnim(false);
    //     if (quantity > 0) {
    //         setTimeout(() => {
    //             setShowAnim(true);
    //         }, 500)
    //     }
    // }, [quantity])


    useEffect(() => {
        let count = 0;
        basketContext.basketItems.forEach(item => {
            count += item.quantity ?? 0;
        })
        setQuantity(count);
    }, [basketContext.basketItems])

    const renderButtonContent = () => {

        if ( type === 'bill' ) {
            return (
                <>
                <span className="basket-btn-content">
                    <span className="basket-btn-label" style={{width: '100%'}}>{label}</span>
                </span>
                </>
            );
        }

        if ( type === 'order-bill') {
            return (
                <>
                <span className="basket-btn-content">
                    <span className="basket-btn-label" style={{width: '100%'}}>{label}</span>
                </span>
                </>
            );
        }

        return (
            <>
                <span className="basket-btn-content">
                    <span className="basket-btn-quantity">
                        <i className="feather feather-shopping-cart feather-20" />
                        <span>{quantity}</span>
                    </span>
                    <span className="basket-btn-label">{label}</span>
                    <span className="basket-btn-divider" />
                    <span className="basket-btn-text">
                        <span data-testid="order-sum" className='order-sum'>{money(basketContext.price.total)}</span>
                    </span>
                </span>
            </>
        );
    };


    return (
        <CTAButton
            disabled={basketContext.loading || ( type === 'order' && !basketContext.basketItems.length)}
            onClick={onClick}
            className={classes + (showAnim ? " anim--basket-pulse" : '') + (basketContext.price.total > 10000 ? ' basket-btn--lg' : '')}
            {...CTAProps}
        >
            {renderButtonContent()}
        </CTAButton>
    );
};

const CTAButton = styled(CTA)`

    ${(props) => props.secondary ? `
        border-color: var(--ui_buttons_cta_basket_bg);
        color: var(--ui_buttons_cta_basket_bg);
    ` : `
        background-color: var(--ui_buttons_cta_basket_bg);
    `}
    
    .basket-btn-content {
        padding-left: 0px;
        display: flex;
        flex: 1 1 0%;
        gap: 12px;
        align-items: center;
        .basket-btn-label {
            flex-shrink: 0;
            white-space: nowrap;
        }
       

        @media screen and (max-width: 767px) {
            padding-left: 0;
            justify-content: space-between;
        }
        .basket-btn-divider {
            border-left: 1px solid var(--border-grey);
            margin: 0 8px;
            height: 20px;
            border-color: var(--ui_buttons_cta_basket_color);
            display: none;
        }
        .basket-btn-quantity {
            flex-grow: 1;
            display: flex;
            flex-basis: 50%;
            gap: 8px;
            align-items: center;
            span {
                display: inline-block;
                text-transform: uppercase;
                min-width: 20px;
            }
        }
        .basket-btn-text {
            flex-grow: 1;
            flex-basis: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
           
        }
        
         
    }
    
    &:not(.mobile-cart-btn) {
        height: 40px;
        padding-left: 16px;
        padding-right: 16px;
        
        .basket-btn-divider {
            display: inline-block !important; 
        }   
        @media screen and (min-width: 768px) {
              .basket-btn-quantity span, .basket-btn-divider {
                   display: none !important;
              }
        }
        
      
        .basket-btn-label { display: none !important; }   
    }
    
    
    @media screen and (max-width: 767px) {
        &:not(.mobile-cart-btn) {
            display: none !important;
        }
    }
    
    @media screen and (min-width: 768px) {
        .basket-btn-quantity span, .basket-btn-divider {
            display: none !important;
        }
    }
`;


export default CartButton;