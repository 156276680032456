import React from 'react';
import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

type NavBadgeProps = {
    children: JSX.Element;
    size?: TSize;
    className?: string;
};

export type TSize = 'lg' | 'md';

const NavBadge = ({ children, size = 'md', className }: NavBadgeProps) => {
    return (
        <NavBadgeWrapper className={className} size={size}>
            {children}
        </NavBadgeWrapper>
    );
};

const NavBadgeWrapper = styled(Nav.Item)<{ size: TSize }>`
    margin: 0px;
    display: inline-block;

    &:first-child { margin-left: 0; }
    &:last-child .nav-link { margin-right: 0; }
    


    .nav-link {

        ${(props) => (props.size === 'md' ? 'padding: 11px 16px;' : null)}
        ${(props) => (props.size === 'lg' ? 'padding: 14px 24px;' : null)}

        max-width: 160px;
        white-space: nowrap;
        text-overflow: ellipsis;

        font-family: var(--text_ui_font);
        font-weight: var(--text_ui_weight);
        letter-spacing: var(--text_ui_spacing);
        font-size: var(--text_ui_select_channel_nav);
        line-height: var(--text_ui_select_channel_nav);
        text-transform: var(--text_ui_case);
        
        color: var(--ui_nav_channels);

        @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 24px;
            padding: 7px 14px;
        }
        
        @media screen and (max-width: 452px) {
            font-size: 14px;
            line-height: 16px;
            padding: 7px 14px;
        }
       

    }


    ${(props) => (props.theme.v3.ux.menu_nav === 'pills' && `
        .nav-link {
            
            border-radius: 40px;
            text-overflow: ellipsis;
            margin-right: 8px;
            transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
            border: 1px solid var(--brand);
            &:hover {
                background-color: var(--brand-10);
            }
            
            &.active:not(.nav-link--solo) {
                // color: var(--ui_nav_menu_color);
                color: #FFFFFF;
                background-color: var(--brand);
            }
            &--solo {
                pointer-events: none;
            }
            
            &:focus {
                outline: none;
                // box-shadow: 0px 0px 0px 4px var(--brand-opaque-15);
            }

        }
        `

    )}

    ${(props) => (props.theme.v3.ux.menu_nav === 'lines' && `
        margin-right: 24px;     
        .nav-link {
            border-bottom: 3px solid transparent;

            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            padding-bottom: 5px;
            

            line-height: 24px;      
            text-align: left;       

            transition: border-color 0.2s ease-in-out;

            &:not(.active):hover {
                border-color: var(--border-grey);
            }

            background-color: transparent;

            &.active {
                margin-bottom: 0;
                border-color: var(--ui_nav_channels);
                background-color: transparent;
                color: var(--ui_nav_channels);
            }

            @media screen and (max-width: 767px) {
               font-family: var(--text_ui_font);
                font-weight: var(--text_ui_weight);
                letter-spacing: var(--text_ui_spacing);
                font-size: var(--text_ui_select_channel_nav);
                line-height: var(--text_ui_select_channel_nav);
                text-transform: var(--text_ui_case);
            }

        //    full width

        max-width: none;
        width: 100%;
        
        }
    `)}
    
    // buttons temporary swap for lines
    
    // ${(props) => (props.theme.v3.ux.menu_nav === 'lines' && `
    //     flex-grow: 1;
    //     .nav-link {
    //         max-width: none;
    //         height: 48px;
    //         text-align: center;
    //
    //         border-bottom: 0;
    //         color: var(--ui_nav_channels);
    //         border-radius: 40px;
    //         padding: 12px 20px;
    //
    //         font-size: var(--text_ui_select_channel_nav);
    //         line-height: 24px;            
    //         font-family: var(--text_ui_font);
    //         font-weight: var(--text_ui_weight);
    //         letter-spacing: var(--text_ui_spacing);
    //         text-transform: var(--text_ui_case);
    //
    //         transition: background-color 0.2s ease-in-out;
    //
    //         @media screen and (max-width: 767px) {
    //             font-size: 16px;
    //         }
    //
    //         &:not(.active):hover {
    //
    //         }
    //
    //
    //         &.active {
    //             margin-bottom: 0;
    //             background-color: var(--brand-10);
    //         }
    //
    //
    //
    //     }
    // `)}
`;

export default NavBadge;
