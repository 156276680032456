import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import styled from 'styled-components';
import useBasketContext from '../../../../contexts/basket/useBasketContext';
import { CTA } from '../../../general/Button';
import {t} from "ttag";
import PageSpinnerThree from "../../../general/PageSpinnerThree";
import useCustomerContext from "../../../../contexts/customer/useCustomerContext";
import {iLog} from "../../../../index";
import mixpanel from "mixpanel-browser";
import {money} from "../../../../services/format";



// type PromoProps = {
//     code: string | null,
// };

const PromoForm = () => {
    const [loading, setLoading] = useState(false);
    const [promoCode, setPromoCode] = useState('');

    const basketContext = useBasketContext();
    const customerContext = useCustomerContext();

    const addPromoCode = () => {
        setLoading(true);

        const email = basketContext.customerEmail ?? customerContext.customerFormData.email;

        iLog('customer email', [email, basketContext.customerEmail, customerContext.customerFormData.email]);

        basketContext.validatePromoCode(promoCode, email)
            .finally(() => {
                setLoading(false);
                setPromoCode('');
                mixpanel.track('Promo applied');
            });

    };

    const removePromo = async () => {
        mixpanel.track('Removing promo');
        try {
            basketContext.setPromoError('');
            await basketContext.validateBasket();
        } catch (error) {
            // if (error.code && [
            //         APIErrorCode.TimeslotMismatch,
            //         APIErrorCode.TimeslotPast,
            //         APIErrorCode.TimeslotExpired
            //     ].includes(error.code)) {
            //     console.log('Timeslot error - show the modal?');
            //     if (showTimeslotModal) {
            //         showTimeslotModal(true);
            //     }
            // }
            basketContext.setLoading(false);
        }
    };


    const renderPromoElement = () => {


        if ( !basketContext.validatedBasket?.codes.length ) {
            return null;
        }

        return (
            // <Code>
            //     <div>
            //         <i className="feather feather-percent feather-16 brand-color-filter"/>
            //         <span>{ discount.reference }</span>
            //         <i onClick={removePromo}
            //            className="cursor-pointer feather feather-x feather-14 brand-color-filter"/>
            //     </div>
            // </Code>
            <StyledPromoLine>
                    { basketContext.validatedBasket?.codes
                        .filter((discount) => discount.type !== 'pos')
                        .map((discount) => {
                            return (
                                <>
                                    <div className={'promo__details'}>
                                        <span className="promo__type">{discount.text}</span>
                                        <Code>
                                            <div>
                                                <i className="feather feather-tag feather-16 brand-color-filter"/>
                                                <span>{discount.reference}</span>
                                                <i onClick={removePromo}
                                                   className="cursor-pointer feather feather-x feather-14 brand-color-filter"/>
                                            </div>
                                        </Code>
                                    </div>
                                    <span className="promo__value">{money(-discount.amount)}</span>
                                </>
                        )})
                    }
            </StyledPromoLine>
        );

    };

    if (basketContext.validatedBasket?.codes.length) {
        return renderPromoElement()
    }

    return (
        <>
            <StyledGroup controlId="formCheckoutPromo" className="promo">
                <Form.Control
                    placeholder="Add promo or gift card code"
                    className=""
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}>
                </Form.Control>
                <i className={'feather feather-tag feather-24 promo__icon'}/>

                {promoCode.length > 0 ? (
                    <CTA
                        className="font-cta submit-promo-btn"
                        disabled={loading}
                        onClick={() => addPromoCode()}
                        block
                    >
                        <PageSpinnerThree show={loading} text={t`Apply`} color={'#FFFFFF'} />
                    </CTA>
                ) : null }
            </StyledGroup>

            { basketContext.promoError !== '' && (
                <ErrorContainer>
                    <i className="feather feather-info feather-20 color-filter-invalid" />
                    { basketContext.promoError }
                </ErrorContainer>
            ) }

        </>
    );
};

const ErrorContainer = styled.div`
    color: var(--invalid);
    padding-top: 8px;
    display: flex;
    align-items: flex-start;
    img { margin-right: 12px; }
    
    & i { margin-right: 8px; }
`;

const Code = styled.div`
    display: inline-block;
    margin-top: 8px;

    div {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        border-radius: 4px;
        background-color: var(--brand-10);
        padding: 5px 8px;
        display: flex;
        align-items: center;
        color: var(--brand);
        a {
            color: var(--brand);
        }
        
        span {
            padding: 0 8px;
            font-size: 16px;
            line-height: 20px;
        }
    }
`;

const StyledGroup = styled(Form.Group)`
    width: 100%;
    margin: 0 !important;
    position: relative;
    .promo-add {
        color: var(--brand);
        cursor: pointer;
        margin-top: 8px;
    }
    input {
        padding-left: 48px;
        
        @media screen and (min-width: 768px) {
            min-width: 220px;
        }
    }
    button {
        width: auto;
    }
    .submit-promo-btn {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        color: var(--brand);
        padding: 0;
        height: auto;
        padding: 4px;
    }

    .promo__icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const StyledPromoLine = styled.div`
    display: flex;
    gap: 12px;
    font-size: 16px;
    .promo {
        &__details {
            flex-grow: 1;
        }
        &__type {
            display: block;
        }
    }
`;


export default PromoForm;
