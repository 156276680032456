import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Radio from "./Radio";
import {darken} from "polished";

interface Props {
    selected: boolean;
    onChange(): any;
    icon?: JSX.Element;
    label: string;
    label_xs?: string;
}

const RadioGroup = (props: Props) => {
    const themeContext = useContext(ThemeContext);
    
    return (
        <StyledRadioGroup tabIndex={0} selected={props.selected} className={'radio-group'} onClick={props.onChange}>
            <Radio selected={props.selected} onChange={props.onChange}/>
            <span className={'radio-group__label'}>{props.label}</span>
            <span className={'radio-group__label radio-group__label--xs'}>{props.label_xs ? props.label_xs : props.label}</span>
            {props.icon ? (<div>{props.icon}</div>) : null}
        </StyledRadioGroup>
    );
}

const StyledRadioGroup = styled.div<{selected: boolean, brand_color?: string}>`
   
    &.radio-group {
        margin: 1px;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        box-shadow: 0 0 0 1px var(--border-input);
        border: 1px solid transparent;
        gap: 12px;
        padding: 10px 16px 10px 16px;
        height: 56px;
        transition: background-color 0.1s linear;
        
        &:first-child {
            margin-bottom: 0;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
        }
        &:last-child {
            margin-top: 0;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        
    }
    .radio-group {
        &__label {
            flex-grow: 1;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            display: none;
            @media screen and (min-width: 420px) {
                display: block;
            }
            &--xs {
                display: block;
                @media screen and (min-width: 420px) {
                    display: none;
                }
            }
        }
        &__icon {
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    
    ${(props) => props.selected ? `
        &.radio-group { 
            background-color: var(--brand-10);
            border-color: var(--brand);
            box-shadow: 0 0 0 1px var(--brand);
            z-index: 10;
        }
        .radio-group__icon {
            color: var(--brand);
        }
        .radio-group__label {
            color: var(--text-black);
        }
    ` : `
        &:hover {
            background-color: var(--bg-grey);
        }
    `}
`;

export default RadioGroup;
