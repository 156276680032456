import React, { useContext, useEffect, useState } from 'react';
import {Accordion, AccordionCollapse, AccordionContext, Card, useAccordionToggle} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';
import config, { ChannelConfigSettings } from '../../../../config/channels';
import { TSite } from '../../../../contexts/site/site-context';
import EatInModal from './EatInModal';
import { darken, rgba } from 'polished';
import mixpanel from "mixpanel-browser";
import {CTA} from "../../../general/Button";


type SiteCardProps = {
    site: TSite;
    channel: ChannelConfigSettings;
};

const SiteCard = ({ site, channel }: SiteCardProps) => {
    const navigate = useNavigate();
    let openingTimes = '';
    let openingHours: any[] = [];
    const isDineIn = channel.name === config.dineIn.name;

    const [eatInModalShow, setEatInModalShow] = useState(false);
    const [spinnerShow, setSpinnerShow] = useState(false);
    const [link, setLink] = useState(`/${channel.handle}/${site.handle}`);
    const [tableNumber, setTableNumber] = useState('0');

    let open = false;



    switch (channel.name) {
        case config.collection.name:
            open = site.channels.collection?.is_open ?? false;
            openingTimes = site.channels.collection?.opening_times ?? '';
            openingHours = site.channels.collection?.opening_hours ?? [];
            break;

        case config.delivery.name:
            open = site.channels.delivery?.is_open ?? false;
            openingTimes = '';
            openingHours = site.channels.delivery?.opening_hours ?? [];
            break;

        case config.dineIn.name:
            open = site.channels.dine_in?.is_open ?? false;
            openingTimes = site.channels.dine_in?.opening_times ?? '';
            openingHours = site.channels.collection?.opening_hours ?? [];
            break;
    }

    const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (open) {
            setSpinnerShow(true);
            // TODO: check if this is deprecated?
            // if (isDineIn) {
            //     setEatInModalShow(true);
            // } else {
                navigate(link);
            // }
        } else {
            setSpinnerShow(true);
            navigate(`${link}?viewOnly=1`);
        }
    }

    useEffect(() => {
        if (isDineIn) {
            setLink(`/${channel.handle}/${site.handle}/table`);
        }
    }, [channel]);

    function CustomToggle({ children, eventKey, callback }: any) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

        const isCurrentEventKey = currentEventKey === eventKey;

        const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            decoratedOnClick(e);
        }

        return (
            <button
                type="button"
                className={`toggler ${isCurrentEventKey ? 'show' : ''}`}
                onClick={handleClick}
            >
                {children}
                <i className={"feather feather-chevron-down feather-18 transition duration-400"} />
            </button>
        );
    }

    const renderOpeningDay = () => {
        return (
            <>

                <div>
                    {
                        openingHours.map((hour) => (
                            <OpeningDay key={'opening_' + hour.day}>
                                <div className="day">{hour.day}</div>
                                <div className="hours">{hour.time.map((time: any) => (
                                    <span>{ time }</span>
                                ))}</div>
                            </OpeningDay>
                        ))
                    }
                </div>
            </>
        )
    }

    const CtaStr = () => {
        if ( !open ) {
            return 'View menu';
        }

        if ( channel.handle === 'collection' ) {
            return 'Start order';
        }

        if ( channel.handle === 'delivery' ) {
            return 'Order for delivery';
        }

        if ( channel.handle === 'at-table' ) {
            return 'Order at table';
        }

        return 'Order for ' + channel.displayName.toLowerCase();
    }

    const CtaLink = () => { return open ? link : link + '?viewOnly=1'; }
    const CtaMixpanelTrack = () => { return open ? 'view menu clicked' : 'view menu clicked (closed)'; }

    const handleCtaClick = () => {
        setSpinnerShow(true);
        mixpanel.track(CtaMixpanelTrack());
        navigate(CtaLink());
    };


    return (
        <StyledCard
            open={open}
            className={`site-card ${open ? 'openRestaurant' : 'closedRestaurant'}`}
        >
            {/*{isDineIn ? (
                <EatInModal
                    show={eatInModalShow}
                    site={site}
                    onTableSelected={(tableNo: string) => {
                        setTableNumber(tableNo);
                        setEatInModalShow(false);
                        navigate(`/${channel.handle}/${site.handle}?table=${tableNo}`);
                    }}
                    onHide={() => {
                        setEatInModalShow(false);
                    }}
                />
            ) : null}*/}
            <div className="card__main" tabIndex={1}
                onClick={handleCardClick}
            >

                <Card.Body>
                    <Card.Title>
                        <h3>{site.name}</h3>
                        {site.distance ? <span>{Math.round(site.distance * 10) / 10} miles</span> : null}
                    </Card.Title>
                    <Card.Text as="div">
                        <div className="location">
                            <span>{site.address_1}</span><span className="sm-hide">,</span><span>{site.address_city} {site.address_postcode}</span>
                        </div>
                    </Card.Text>
                    <StyledAccordion defaultActiveKey="-1">
                        {/* <AccordionToggle eventKey="0">
                    {t`View`} {bItems.length} {t`items in basket`}
                </AccordionToggle> */}

                        <CustomToggle eventKey="0" >
                            <span onClick={()=> mixpanel.track('View location info')}>
                                {open ?
                                    (
                                        <span >{openingTimes}</span>
                                    )
                                    : (
                                        <span >Not currently accepting orders</span>
                                    )
                                }
                            </span>
                            {/*<span className="on-open">Less info</span>*/}
                            {/* <span className="on-open">Close</span> */}
                        </CustomToggle>
                        <AccordionCollapse eventKey="0">
                            <>
                                <div className="opening-hours-block">
                                    {openingHours.length > 0 ? renderOpeningDay() : null}
                                </div>

                                {/*<div>*/}
                                {/*    <a href={`tel:${site.phone}`} className="one-liner">*/}
                                {/*        <i className="feather feather-phone feather-20 brand-color-filter"/>*/}
                                {/*        <span>{ site.phone }</span>*/}
                                {/*    </a>*/}
                                {/*</div>*/}

                                {/*<div>*/}
                                {/*    <a href={`https://www.google.com/maps/@${site.lat},${site.lng},17z`} target='_blank' className="one-liner" rel="noreferrer">*/}
                                {/*        <i className="feather feather-map feather-20 brand-color-filter"/>*/}
                                {/*        <span>Get directions</span>*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                            </>
                        </AccordionCollapse>
                    </StyledAccordion>
                </Card.Body>
                <Card.Footer>
                   <CTAButton open={open} className={`card__btn`} onClick={handleCtaClick}>
                        <span>{ CtaStr() }</span>
                        {/*<i className={'feather feather-arrow-right feather-20'}></i>*/}
                    </CTAButton>
                </Card.Footer>
            </div>

        </StyledCard>
    );
};

const CTAButton = styled(CTA)<{open: Boolean}>`
    display: inline-flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: var(--text_ui_weight);
    font-family: var(--text_ui_font);
    text-transform: var(--text_ui_case);
    color: var(--brand);
    background: transparent;
    font-size: 18px;
    line-height: 18px;
    padding: 8px 20px;
    border-radius: 6px;
    height: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.open ? 'var(--brand);' : 'var(--border-grey);'}
   
    transition: background-color 0.15s linear, color 0.15s linear, border-color 0.15s linear;
    &:hover, &:focus {
        background: var(--brand-10);
    }
    @media (min-width: 768px) {
        width: auto;
    }
`;

const StyledAccordion = styled(Accordion)`
    .collapse {
        padding-bottom: 8px;
    }
    .toggler {
        display: flex;
        align-items: center;
        gap: 6px;
        border: 0;
        background-color: transparent;
        padding: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        // color: var(--text_display_h3_color);
        i {
            transition: transform 0.2s linear;
        }
        .on-open { display: none };
        &:hover { text-decoration: none }
        &.show {
            .on-open { display: block }
            .on-closed { display: none }
            i {
                transform: rotate(180deg);
            }
        }
        transition: background-color 0.2s linear;
        &:not(.show) {
            // &:hover {
            //     transition: background-color 0.1s linear;
            //     background-color: ${(props) => rgba(props.theme.v3.ui.other.link, 0.05)};
            // }
        }
    }

    .opening-hours-block {
        padding: 8px 0 16px 0;
        display: flex;
        gap: 16px;
        &__icon{

        }
    }

    .one-liner {
        &:hover { text-decoration: none !important; }
        
        & > i { margin-right: 16px; }
        
        color: var(--brand);
        display: flex;
        align-items: center;
        padding: 12px 0;
        font-weight: 500;
        border-color: var(--border-grey);
        transition: color 0.2s linear;
        &:hover i {
            text-decoration: none !important;
            color: ${(props) => darken(0.05, props.theme.v3.ui.other.link)};
        }
        &:hover span {
            text-decoration: underline;
        }
    }
`;

const OpeningDay = styled.div`
    display: flex;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
    color: var(--text-black);
    font-weight: 500;

    &:first-child { margin-top: 2px;}
    & > div:first-child { margin-right: 16px; }

    .icon-open { color: var(--text-grey); font-size: 16px; font-weight: 500; }
    .icon-closed { color: var(--invalid); font-size: 16px; font-weight: 500; }
    .hours { 
        flex-grow: 1; text-align: right; display: flex;
        flex-direction: column;
        gap: 5px;
        span {
            display: block;
        }
    }
    .day {
        min-width: 120px;
        letter-spacing: -0.02em;

    }
    @media (max-width: 420px) {
        font-size: 14px;
        line-height: 16px;
        div, span {
            letter-spacing: -0.02em;
        }
        .day {
            min-width: 76px;
        }
    }
`;


const StyledCard = styled(Card)<{ open: boolean }>`
    cursor: pointer;
    
    .card__link {
        display: inline-flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        color: var(--brand);
        background: transparent;
        font-size: 18px;
        line-height: 18px; 
        padding: 4px 0;
        border: 0;
        font-weight: var(--text_ui_weight);
        font-family: var(--text_ui_font);
        border-bottom: 1px solid var(--brand);
        text-transform: var(--text_ui_case);
        border-radius: 0;
        height: auto;
    }

    .card__main {
        transition: background-color 0.15s linear; 
        ${(props) => !props.open ? 'background-color: var(--bg-grey);' : ''}
        &:hover {
            // background-color: ${(props) => rgba(props.theme.v3.ui.other.link, 0.05)};
            ${CTAButton} {
                // color: white;
                // background: var(--brand);
                border-color: var(--brand);
                background: var(--brand-10);
            }
        }
    }


    transition: box-shadow .15s linear;

    .card-title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        gap: 8px;
        flex-wrap: wrap;
        h3 {
            flex-grow: 1;
            margin-bottom: 0;
            line-height: 24px;
        }
        span {
            color: var(--text-grey);
            font-size: 15px;
        }
    }
    .card-body {
        padding-bottom: 0;
        @media screen and (min-width: 768px){
                padding: 20px 20px 0 20px;

        }
        font-size: 15px;


        .location {
            font-size: 16px;
            color: var(--text-grey);
            margin-bottom: 8px;
            font-weight: 500;
            line-height: 20px;
            
            span.sm-hide {
                margin-right: 3px;
                @media screen and (max-width: 767px) {
                    display:none;
                }
            }
            @media screen and (max-width: 767px) {
                flex-direction: column;
            }
        }

        .location,
        .openingTimes { display: flex; align-items: start; }

        .location i,
        .openingTimes i { margin-right: 8px; line-height: 24px; font-size: 16px; }

        .openingTimes,
        .notAcceptingOrders {
            font-size: 16px;
            color: var(--text-black)
            line-height: 20px;
            font-weight: 500;
            @media screen and (min-width: 1441px) {
                margin-bottom: 8px;
            }
        }
        .notAcceptingOrders {
            font-weight: 600;
        }
    }

    .card-footer {
        padding: 20px;
        background: transparent;
        border: none;
        display: flex;
        justify-content: flex-end;

        .card__btn {
            @media screen and (min-width: 768px) {
                width: 50%;
                max-width: 292px;
            }
            position: relative;
            i { 
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 12px;
                color: var(--brand);
            }
        }
    }
    
    @media screen and (min-width: 768px) {
        &:first-child { margin-top: 8px; }
        margin-bottom: 20px;
        transition: box-shadow 0.15s linear, border-color 0.15s linear;
        box-shadow: 0px 4px 10px -4px rgb(0 0 0 / 10%), 0 2px 4px -2px rgba(0 0 0 / 5%), 0 0px 2px rgba(0 0 0 / 5%);
        
        
        ${(props) => props.theme.v3.ux.menu_tiles === 'flat' && `
            box-shadow: none;
        `}
        
        &:hover {
            // box-shadow: 0 12px 20px 0 rgb(0 0 0 / 10%) !important;
            box-shadow: 0 4px 6px 1px rgb(0 0 0 / 7.5%) !important;
            border-color: #E0E0E0;
        }
    }

`;

export default SiteCard;
