import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

type ModifierCheckboxQuantityProps = {
    quantity: number;
    setQuantity: (value: number) => void;
    maxReached: boolean;
};

const ModifierCheckboxQuantity = ({ quantity, setQuantity, maxReached }: ModifierCheckboxQuantityProps) => {

    const updateModifierQuantity = (value: number) => {
        if (maxReached && value > 0) {
            return;
        }

        setQuantity(quantity + value);
    };

    return (
        <QuantityWrapper>
            <button onClick={() => updateModifierQuantity(-1)} type="button" aria-label="Decrease Quantity">
                <i className="feather feather-minus-circle feather-20 brand-color-filter" />
            </button>
            <span>{quantity}</span>
            <button onClick={() => updateModifierQuantity(1)} type="button" aria-label="Increase Quantity">
                <i className="feather feather-plus-circle feather-20 brand-color-filter" />
            </button>
        </QuantityWrapper>
    );
};

export default ModifierCheckboxQuantity;

const QuantityWrapper = styled.div`
    padding-left: 15px;
    display: flex;
    align-items: center;
    gap: 4px;
    span {
        width: 20px;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        color: var(--text-black);
    }
    button {
        padding: 0;
        border: none;
        margin: 0;
        font: inherit;
        color: inherit;
        background-color: transparent;
        text-align: inherit;
        cursor: pointer;
        line-height: 20px;
        height: 20px;
        &:focus {
            outline: none;
            svg {
                border-radius: 25px;
            }
        }
    }
`;
