import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { t } from 'ttag';
import ErrorPage from './Error/ErrorPage';
import SiteRoutes from './SiteRoutes';

const PageRoutes = () => {
    return (
        <Routes>
            <Route
                path={`/404`}
                element={
                    <ErrorPage
                        title={t`Sorry, this page was not found.`}
                        message={t`Go back to the <a href="/" class="text-brand cursor-pointer">homepage</a> or "contact" us about a problem.`}
                    />
                }
            />
            <Route
                path={`/500`}
                element={
                    <ErrorPage
                        title={t`Sorry, there was an unexpected error.`}
                        message={t`Please try again or contact us if the issue persists.`}
                    />
                }
            />
            <Route path={`/*`} element={<SiteRoutes />} />

            {/* <Route path="*">
                <ErrorPage />
            </Route> */}
        </Routes>
    );
};

export default PageRoutes;
