import {darken, lighten, readableColor, rgba} from 'polished';
import React, {useContext, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import {createGlobalStyle, keyframes, ThemeContext} from 'styled-components';
import {Font, Theme} from '../../../contexts/operator/operator-context';
import Banner from './components/Banner';
import Content from './components/Content';
import Header from './components/Header';
import Navbar from './components/Navbar';
import dropArrow from '../../../assets/images/drop-arrow.svg';
import OrderswiftBanner from './components/OrderswiftBanner';
import useScrollPosition from "../../../hooks/useScrollPosition";
import {setCspContent} from '../../../csp';
import useOperatorContext from "../../../contexts/operator/useOperatorContext";

type AppLayoutProps = {
    children: JSX.Element | JSX.Element[];
    page: TPage;
    banner?: string;
};

export type TPage = 'channels' | 'order-at-table' | 'sites' | 'menu' | 'menu-new' | 'checkout' | 'confirmation' | null;

const AppLayout = ({ children, page, banner }: AppLayoutProps) => {
    const themeContext = useContext(ThemeContext);
    const operatorContext = useOperatorContext();
    const scrollPosition = useScrollPosition();
    // const renderBanner = () => {
    //     if (!banner) {
    //         return null;
    //     }
    //
    //     return <Banner scrolled={scrollPosition > 40} text={banner} />;
    // };

    // document.querySelector('meta[name="theme-color"]')?.setAttribute('content',  `${themeContext.color_primary}`);
    document.querySelector('meta[name="icon"]')?.setAttribute('href',  `${themeContext.v3.favicon_url}`);

    const addMetaScript = () => {
        if ( navigator.doNotTrack || !operatorContext.operator?.meta_tag || document.getElementById('meta') ) {
            return null;
        }

        const script = document.createElement("script");
        script.id = 'meta';
        script.append("!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');fbq('init', '"+ operatorContext.operator.meta_tag +"');fbq('track', 'PageView');");
        document.head.appendChild(script);

        console.log('Enabled for Meta');
    }

    const addGoogleTagManagerScript = () => {
        if ( navigator.doNotTrack || !operatorContext.operator?.google_analytics || document.getElementById('gtm') ) {
            return null;
        }

        const script = document.createElement('script');
        script.id = 'gtm';
        script.append("dataLayer = [];(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" + operatorContext.operator?.google_analytics  + "');")
        document.head.appendChild(script);

        console.log('Enabled for Google Tag Manager');
    }

    return (
        <div className={`page-${page } ${scrollPosition > 39 ? 'banner-hidden' : ''}`}>
            <Helmet>
                <link rel="icon" type="image/png" href={themeContext.v3.favicon_url} />
                <link
                    href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&amp;display=swap"
                    rel="stylesheet"
                />

                {/*<meta httpEquiv="Content-Security-Policy" content="default-src 'self'; script-src 'self' https://js.stripe.com https://cdn.mxpnl.com; style-src 'self' https://fonts.googleapis.com; img-src 'self' https://s3.eu-west-2.amazonaws.com/orderswift-production/; font-src 'self' https://fonts.googleapis.com https://s3.eu-west-2.amazonaws.com/orderswift-production/; connect-src 'self' https://sessions.bugsnag.com https://notify.bugsnag.com https://api-js.mixpanel.com https://api.orderswift.com https://js.stripe.com; frame-src https://js.stripe.com" />*/}
                <meta httpEquiv="Content-Security-Policy" content={setCspContent()} />
                <meta name="referrer" content="strict-origin-when-cross-origin" />
                <meta httpEquiv="Permissions-Policy" content="payment=(self), geolocation=(self)"/>

                {addMetaScript()}
                {addGoogleTagManagerScript()}

            </Helmet>
            {/* {renderStripeTestModeBanner()} */}
            {/* {renderBanner()}*/}
            <Navbar page={page} />
            <Header page={page}>
                {
                    themeContext.fonts.map((font: Font, index: Number) => (
                        <GlobalFont font={font} key={'font_' + index} /> ))
                }
                <GlobalStyle theme={themeContext} />
                <FeatherIcons />
            </Header>
            <Content page={page}>
                <>{children}</>
            </Content>
            <OrderswiftBanner page={page}/>
        </div>
    );
};

const GlobalFont = createGlobalStyle<{ font: Font }>`
    @font-face {
        font-family: ${(props) => props.font.name};
        src: url(${(props) => props.font.woff2}) format('woff2');
        font-weight: ${(props) => props.font.weight};
        font-style: normal;
    }
`;

const basketPulse = (theme:Theme) => keyframes`
    0%      { box-shadow: 0 0 0 0 ${rgba(theme.v3.ui.buttons.cta_basket.bg, 0.7)}; }
    70%     { box-shadow: 0 0 0 10px ${rgba(theme.v3.ui.buttons.cta_basket.bg, 0)}; }
    100%    { box-shadow: 0 0 0 0 ${rgba(theme.v3.ui.buttons.cta_basket.bg, 0)}; }
`;


const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
    
    :root { 
        --logo:         url('${(props) => props.theme.v3.logo_url}'); 
        --logo_height:  ${(props) => props.theme.v3.logo_height}; 
        --layouts_hero: url('${(props) => props.theme.v3.layouts.hero}');
        
        --layouts_body_channel_select_bg: ${(props) => props.theme.v3.layouts.body.channel_select_bg}; 
        --layouts_body_site_select_bg: ${(props) => props.theme.v3.layouts.body.site_select_bg}; 
        --layouts_body_menu_bg: ${(props) => props.theme.v3.layouts.body.menu_bg}; 
        
        --layouts_headers_primary_bg: ${(props) => props.theme.v3.layouts.headers.primary_bg}; 
        --layouts_headers_secondary_bg: ${(props) => props.theme.v3.layouts.headers.secondary_bg};
        --layouts_headers_borders_channels: ${(props) => props.theme.v3.layouts.headers.borders.channels ? '1px solid #EAEAEA' : 'none'};
        --layouts_headers_borders_sites: ${(props) => props.theme.v3.layouts.headers.borders.sites ? '1px solid #EAEAEA' : 'none'};
        --layouts_headers_borders_menu: ${(props) => props.theme.v3.layouts.headers.borders.menu ? '1px solid #EAEAEA' : 'none'};
        
        --ui_nav_channels: ${(props) => props.theme.v3.ui.nav.channels}; 
        --ui_nav_channels_light: ${(props) => '#EAEAEA'}; 
        
        --ui_nav_menu_bg: ${(props) => props.theme.v3.ui.nav.menu.bg}; 
        --ui_nav_menu_bg_opa0: ${(props) => props.theme.v3.ui.nav.menu.bg + '00'}; 
        --ui_nav_menu_color: ${(props) => props.theme.v3.ui.nav.menu.color}; 
        --ui_nav_menu_color_hover: ${(props) => props.theme.v3.ui.nav.menu.color + '1A'}; 
        --ui_nav_menu_color_light: ${(props) => lighten(.35,  props.theme.v3.ui.nav.menu.color)}; 
        --ui_nav_menu_border: 1px solid ${(props) => props.theme.v3.ui.nav.menu.border}; 
         
        --ui_banner_bg: ${(props) => props.theme.v3.ui.banner.bg}; 
        --ui_banner_color: ${(props) => props.theme.v3.ui.banner.color}; 
         
        --ui_buttons_cta_bg: ${(props) => props.theme.v3.ui.buttons.cta.bg}; 
        --ui_buttons_cta_color: ${(props) => props.theme.v3.ui.buttons.cta.color}; 
         
        --ui_buttons_cta_alt_border: ${(props) => props.theme.v3.ui.buttons.cta_alt.border}; 
        --ui_buttons_cta_alt_color: ${(props) => props.theme.v3.ui.buttons.cta_alt.color}; 
         
        --ui_buttons_cta_basket_bg: ${(props) => props.theme.v3.ui.buttons.cta_basket.bg}; 
        --ui_buttons_cta_basket_color: ${(props) => props.theme.v3.ui.buttons.cta_basket.color}; 
       
        --ui_buttons_cta_disabled_bg: ${(props) => props.theme.v3.ui.buttons.cta_disabled.bg}; 
        --ui_buttons_cta_disabled_color: ${(props) => props.theme.v3.ui.buttons.cta_disabled.color}; 
          
        --ui_buttons_change_location_bg: ${(props) => props.theme.v3.ui.buttons.change_location.bg}; 
        --ui_buttons_change_location_color: ${(props) => props.theme.v3.ui.buttons.change_location.color}; 
        --ui_buttons_change_location_size: ${(props) => props.theme.v3.ui.buttons.change_location.size}; 
 
        --ui_other_link: ${(props) => props.theme.v3.ui.other.link}; 
        --ui_other_badge: ${(props) => props.theme.v3.ui.other.badge}; 
        
        --ui_tile_radius: ${(props) => props.theme.v3.ui.tiles.radius}; 
 
        --text_display_font: ${(props) => props.theme.v3.text.display.font}; 
        --text_display_weight: ${(props) => props.theme.v3.text.display.weight}; 
        --text_display_spacing: ${(props) => props.theme.v3.text.display.spacing}; 
        --text_display_case: ${(props) => props.theme.v3.text.display.case}; 
         
        --text_display_h1_height: ${(props) => props.theme.v3.text.display.h1.height}; 
        --text_display_h1_color: ${(props) => props.theme.v3.text.display.h1.color}; 
         
        --text_display_h2_height: ${(props) => props.theme.v3.text.display.h2.height}; 
        --text_display_h2_color: ${(props) => props.theme.v3.text.display.h2.color}; 
         
        --text_display_h3_height: ${(props) => props.theme.v3.text.display.h3.height}; 
        --text_display_h3_color: ${(props) => props.theme.v3.text.display.h3.color}; 
         
        --text_display_h4_height: ${(props) => props.theme.v3.text.display.h4.height}; 
        --text_display_h4_color: ${(props) => props.theme.v3.text.display.h4.color}; 
         
        --text_ui_font: ${(props) => props.theme.v3.text.ui.font}; 
        --text_ui_weight: ${(props) => props.theme.v3.text.ui.weight}; 
        --text_ui_spacing: ${(props) => props.theme.v3.text.ui.spacing}; 
        --text_ui_case: ${(props) => props.theme.v3.text.ui.case}; 
         
        --text_ui_cta: ${(props) => props.theme.v3.text.ui.cta}; 
        --text_ui_select_channel_nav: ${(props) => props.theme.v3.text.ui.select_channel_nav}; 
        --text_ui_menu_nav: ${(props) => props.theme.v3.text.ui.menu_nav}; 
         
                 
        --invalid:      #ED4946; 
        // --text-black:   #2A2A2A; 
        // --text-black-lite: #494949; 
        // --text-grey:    #737373; 
        --text-black:   #000000; 
        --text-black-lite: #000000; 
        --text-grey:    #8E8E93; 
        --border-grey:  #E5E5EA; 
        --border-input: #E5E5EA; 
        --border-input-hover: #C1C1C1; 
        --placeholder:  #8E8E93; 
        --bg-grey:      #FAFAFA; 
        --btn-grey:     #E2E5E5;
        --bg-disabled:  #E9ECEF;
         
        --card-border:  1px solid #E5E5EA; 
        --card-radius:  8px; 
        --card-shadow:  none; 
         
        --brand:            ${(props) => props.theme.v3.ui.other.link}; 
        --brand-10:         ${(props) => rgba(props.theme.v3.ui.other.link, 0.1)}; 
        --brand-dark-20:    ${(props) => readableColor(darken(0.2, "#000000"))};
        --brand-opaque-15:  ${(props) => rgba("#000000", 0.15)};
    } 

    html {
        scroll-behavior: smooth;
        height: 100%;
    }

    /* Typography */

    // Body Font (Default Inter)

    @font-face {
        font-family: ${(props) => props.theme.bodyFont.name};
        src: local(${(props) => props.theme.bodyFont.name}),
        url(${(props) => props.theme.bodyFont.woff2}) format('woff2'),
        url(${(props) => props.theme.bodyFont.woff}) format('woff'),
        url(${(props) => props.theme.bodyFont.ttf}) format('ttf');
        font-weight: ${(props) => props.theme.bodyFont.weight};
        font-style: normal;
    }
    
    .feather-14 { font-size: 14px; }
    .feather-16 { font-size: 16px; }
    .feather-18 { font-size: 18px; }
    .feather-20 { font-size: 20px; }
    .feather-24 { font-size: 24px; }
 
    .brand-color-filter             { color: var(--brand); }
    .text-brand                     { color: var(--brand); }
    .color-filter-invalid           { color: var(--invalid); }
    .color-filter-change-location   { color: var(--ui_buttons_change_location_color); }
    .color-filter-737373            { color: #737373;}
    .color-filter-cart-cta          { color: var(--ui_buttons_cta_basket_color); }
    .color-filter-badge-icon        { color: var(--ui_other_badge); }
   
    .border-grey                    { border-color: var(--border-grey) }
    .disabled-bg                    { background-color: var(--bg-disabled) }
    .form-control:disabled, .form-control[readonly] { background-color: var(--white) !important; }
    
    .channel-select-container h1 { text-transform: none; }

    .cursor-pointer:hover { cursor:pointer; }
    
    
    body {
        margin: 0;
        font-family: ${(props) =>
            props.theme.bodyFont.name}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: ${(props) => props.theme.bodyFont.size}px;
        color: var(--text-black); // Body Colour
        overflow: auto;
        overflow-x: hidden;
        line-height: 20px;
        height: 100%;
        overscroll-behavior-y: none;
    }

    #root {
        height: 100%;
    }

    .page-sites {
        min-height: 100%;
    }

    .p-lead { // Small Heading Styles
        color: ${(props) => props.theme.color_shade_1};
        margin-bottom: 0.5rem;
        font-weight: 600;
    }

    .p-title {
        display: block;
        font-weight: 600;
        margin-bottom: 8px;
    }

    h1, h2, h3, h4, h5, h6 {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
        text-transform: var(--text_display_case);
        font-family: var(--text_display_font);
        font-weight: var(--text_display_weight);
        letter-spacing: var(--text_display_spacing);
    }

    h1 {
        font-size: var(--text_display_h1_height);
        line-height: var(--text_display_h1_height);
        color: var(--text_display_h1_color);
    }

    h2 {
        font-size: var(--text_display_h2_height);
        line-height: var(--text_display_h2_height);
        color: var(--text_display_h2_color);
    }

    h3 {
        font-size: var(--text_display_h3_height);
        line-height: var(--text_display_h3_height);
        color: var(--text_display_h3_color);
    }

    h4 {
        font-size: var(--text_display_h4_height);
        line-height: var(--text_display_h4_height);
        color: var(--text_display_h4_color);
    }


    .site-card {
        overflow: hidden;
        border-radius: 0;
        border-width: 0;
        &:first-child {
            border-top-width: 1px;
        }
        border-bottom-width: 1px;
        
        @media screen and (min-width: 768px) {
            border-radius: var(--ui_tile_radius);
            border: 1px solid #EAEAEA;
            box-shadow: 0px 4px 10px -4px rgb(0 0 0 / 10%), 0 2px 4px -2px rgba(0 0 0 / 6%), 0 0px 2px rgba(0 0 0 / 6%) !important;
            
            ${(props) => props.theme.v3.ux.menu_tiles === 'flat' && `
                box-shadow: none !important;
            `}
        }
    }
    
    // .anim--basket-pulse {
    //     animation: ${(props) => basketPulse(props.theme)} 1.25s;
    //     box-shadow: 0 0 0 0 ${(props) => rgba(props.theme.v3.ui.buttons.cta_basket.bg, 1)};
    // }
   
    code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

    label {
        font-size: 15px;
        font-weight: 400;
    }

    .navbar-brand {
        cursor: pointer;
        margin: 0 auto;
        display: flex;
        padding: 0.3125rem 0;
        img {
            max-width: 100%;
        }
        @media screen and (min-width: 768px){
            margin: 0;
        }
    }

    /* Overwrite BS Classes */

    .text-primary {color: ${(props) => props.theme.color_primary} !important;}
    .text-secondary {color: ${(props) => props.theme.color_secondary} !important;}



    .text-success {color: ${(props) => props.theme.color_success} !important;}
    .text-danger {color: var(--invalid) !important;}
    .text-warning {color: ${(props) => props.theme.color_warning} !important;}
    .text-info {color: ${(props) => props.theme.color_info} !important;}


    .alert-primary {background-color: ${(props) => props.theme.color_primary_shade}; color: ${(props) =>
    props.theme.color_primary};}
    .alert-secondary {background-color: ${(props) => props.theme.color_secondary_shade}; color: ${(props) =>
    props.theme.color_secondary};}
    .alert-success {background-color: ${(props) => props.theme.color_success_shade}; color: ${(props) =>
    props.theme.color_success};}
    .alert-danger {background-color: ${(props) => props.theme.color_danger_shade}; color:var(--invalid);}
    .alert-warning {background-color: ${(props) => props.theme.color_warning_shade}; color: ${(props) =>
    props.theme.color_warning};}
    .alert-info {background-color: ${(props) => props.theme.color_info_shade}; color: ${(props) =>
    props.theme.color_info};}

    .font-weight-bold {
        font-weight: 600 !important;
    }
    .font-weight-md-bold {
        @media screen and (min-width: 768px){
            font-weight: 600 !important;
        }
    }
    strong, b {
        font-weight: 600;
    }

    .badge {
        font-size: 14px;
        font-weight: 600;
        padding: 0 6px;
        margin-right: 8px;
        border-radius: 6px;
        .badge__icon {
            margin-right: 3px;
        }
    }


    a, button {
        touch-action: manipulation;
    }

    .btn-icon {
        svg {
        float: right;
        }
    }

    hr {
        border-top: 1px solid ${(props) => props.theme.color_border}
    }


    // Forms

    .custom-select {
        border-radius: 4px;
        border: 1px solid ${(props) => props.theme.color_border}; // Border Colour
        border-radius: 4px;
        font-size: 16px;
        height: 44px;
        padding: 0 12px;
        &:focus {
            border-color: ${(props) => rgba(props.theme.color_secondary, 0.2)};
            box-shadow: 0px 0px 0px 4px ${(props) => rgba(props.theme.color_secondary, 0.15)};
        }
    }

    .separator {
        margin: 16px 0;
        border-top: 2px solid #F7F7F7;
        &.thin, &.tiny {
            border-top: 1px solid var(--border-grey);
            border-top-color: #D3D3D3;
        }
    }

    /*
    * Modals
     */

    .modal-order-backdrop { z-index: 1040; }
    .order-modal-dialog { z-index: 1041}

    .modal-item-backdrop { z-index: 1045; }
    .item-modal-dialog { z-index: 1046}

    .modal-item-allergens-backdrop { z-index: 1047; }


    .modal-header .close {
        margin: 0;
        line-height: 16px;
        border-radius: 32px;
        color: var(--brand);
        opacity: 1;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        padding: 1rem;
        font-weight: 500;
        
    }
    
    
    .modal-close__wrapper {
        position: absolute;
        right: 1rem;
        height: 40px;
        width: 40px !important;
        background-color: var(--white);
        border-radius: 50%;
        .modal-close__btn {
            border: none;
            padding: 0;
            background: transparent;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            line-height: 0;
            transition: background-color 0.15s ease-in-out;
            :hover {
                background-color: var(--brand-10);
            }
        }
    }

    

    .modal-dialog {
        height: 100dvh;
        width: 100vw;
        left: 0;
        top: 0;
        overflow: hidden !important;
        display: flex !important;
        justify-content: center;
        margin: 0 !important;
        max-width: 100% !important;
        background-color: transparent;
        display: flex;
        @media screen and (min-width: 768px) {
            padding: 24px 16px;
        }

        opacity: 0;
        transform: scale3d(.9,.9,1);

        transition: opacity .3s ease, transform .3s ease;
        transition-delay: .2s;

        &.anim-enter {
            opacity: 1;
            transform: scaleX(1);
        }


        .modal-content {
            position: fixed;
            top: 0;
            height: 100%;
            left: 0;
            background: ${(props) => props.theme.color_bg};
            display: flex;
            flex-direction: column;
            width: 767px;
            max-height: 100%;
            border: 0;
            overflow: hidden;
            position: relative;
            border-radius: 0;
            @media screen and (min-width: 768px) {
                border-radius: 8px;
                max-width: 600px;
                height: auto;
            }
        }

        .modal-header,
        .modal-footer {
            border: 0;

            form {
                margin: 0 !important;
            }
        }
        .modal-dialog {
            align-items: initial;
            width: 100%;
            max-width: none;
            margin: 0;
            flex-basis: 520px;
        }

        .modal-footer {
            border: 1px solid var(--border-grey);
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            padding: 20px;
            position: relative;
            background: white;
            justify-content: space-between;
            border: none;

            &.modal-show-footer-shadow { box-shadow: 0px 0px 6px rgb(0 0 0 / 16%); }
        }
    }

    /*
    *Dropdowns
    */
    .dropdown {
    .dropdown-toggle {
        width: 100%;
        background: white !important;
        border: 1px solid #cccccc !important;
        border-radius: 4px;
        color: var(--text-black) !important;
        text-align: left;
        font-weight: 600;
        padding: 0 16px;
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 576px) {
            font-size: 14px;
        }

        &:focus,
        &:active {
            outline: none !important;
            box-shadow: none !important;
            border: 1px solid #cccccc !important;
        }

        &::after {
            border: none;
            margin: none;
            width: 12px;
            height: 8px;
            background: url(${dropArrow});
            background-size: cover;
        }
    }
    .dropdown-menu {
        width: 100%;
        padding: 0;
        border: 1px solid #cccccc;
        border-radius: 4px;

        .dropdown-item {
            &:hover {
                background: ${(props) => props.theme.color_border};
            }
            font-weight: 400;
            padding: 10px 16px;
            font-size: 16px;

            @media screen and (max-width: 576px) {
                font-size: 14px;
            }
        }
    }

    &.show {
        .dropdown-toggle {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            &::after {
                transform: rotate(180deg);
            }
        }
        .dropdown-menu {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            transform: translate(0, 43px) !important;
        }
    }
    }

    /*
    * Form controls
    */
    .form-group {
        margin-bottom: 16px;
        label {
            font-size: 15px;
            color: var(--text-grey);
            margin-bottom: 7px;

            @media screen and (max-width: 576px){
                font-size: 15px;
            }
        }
    }
    .form-control {
        border-radius: 6px;
        font-size: 15px;
        height: 48px;
        padding: 0 16px;
        border: 1px solid var(--border-input);
        box-shadow: none;
        transition: border-color 0.15s ease-in-out, outline 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        color: var(--text-black);
        &:hover:not([disabled]) {
            border-color: var(--border-input-hover);
        }

        &:focus:not([disabled]) , &.StripeElement--focus:not([disabled])  {
            border-color: var(--brand);
            box-shadow: 0 0 0 1px var(--brand) !important;
            outline: none;
            color: var(--text-black);
        }
        &.is-invalid:not([disabled]) ,
        &.StripeElement--invalid:not([disabled])  {
            border-color: var(--invalid);
            border-width: 2px;
            background-image: none;
        }
        &.is-valid {
            background: #ffffff;
            border-color: var(--border-input) !important;
        }
        &.is-valid:focus:not([disabled])  {
            background: #ffffff;
            border-color: var(--brand) !important;
        }
        &.is-invalid:focus:not([disabled]) , &.StripeElement--invalid.StripeElement--focus:not([disabled])  {
            box-shadow: 0 0 0 1px var(--invalid) !important;
        }
        &::placeholder {
            color: var(--placeholder);
        }
    }    
   
    input[type='number'] {
        -moz-appearance:textfield;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .form-control--textarea {
        &:focus {
            border: 1px solid var(--brand);
            /*box-shadow: 0 0 0 4px  ${(props) => rgba( props.theme.v3.ui.other.link, 0.1)} !important;*/
            box-shadow: none;
            outline: none;
        }
    }
    .invalid-feedback {
        font-size: 15px;
        line-height: 24px;
        font-weight: 400;
        margin-top: 4px;
        color: var(--invalid);
    }

    .input-group {
        .dropdown {
            .dropdown-toggle {
                height: 100%;
                padding: 0 12px 0 20px;
                color: ${(props) => props.theme.color_primary} !important;
                font-weight: 400;
                font-size: 16px;
                border-right: none !important;

                &::after {
                    margin-left: 9px;
                }
            }
        }
    }
    
    .form--submitting {
        .form-control {
            pointer-events: none;
            background-color: var(--bg-grey);
        }
    }

    .page-checkout .scrolled {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    }

    .page-menu.banner-hidden {
        .banner-alert {
            transition-duration: 250ms;
            transform: translateY(-40px);
            transition: transform 100ms ease-out 0s;
        }
    }
    
    .fab__row {
        display: flex;
        justify-content: end;
        position: fixed;
        max-width: 1920px;
        bottom: calc(81px + 20px);
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;
        pointer-events: none;
        @media screen and (min-width: 767px) {
            bottom: 20px;
            padding: 0 48px;
        }
    }
    

    ${(props) => props.theme.v3.extra_css}

    @media screen and (max-width: 767px){
        ::-webkit-scrollbar {
            width: 5px;
            height: 8px;
            background-color: white; /* or add it to the track */
        }

        ::-webkit-scrollbar-thumb {
            background: var(--brand);
        }
    }

    .__PrivateStripeElement,
    .ElementsApp {
        height: 48px;
    }
`;

const FeatherIcons = createGlobalStyle<{}>`
    @font-face {
      font-family: 'feather';
      src:  url('/feather.eot?7fk1iu');
      src:  url('/feather.eot?7fk1iu#iefix') format('embedded-opentype'),
        url('/feather.ttf?7fk1iu') format('truetype'),
        url('/feather.woff?7fk1iu') format('woff'),
        url('/feather.svg?7fk1iu#feather') format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: block;
    }

.feather {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'feather' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.feather-activity:before {
  content: "\\e900";
}
.feather-airplay:before {
  content: "\\e901";
}
.feather-alert-circle:before {
  content: "\\e902";
}
.feather-alert-octagon:before {
  content: "\\e903";
}
.feather-alert-triangle:before {
  content: "\\e904";
}
.feather-align-center:before {
  content: "\\e905";
}
.feather-align-justify:before {
  content: "\\e906";
}
.feather-align-left:before {
  content: "\\e907";
}
.feather-align-right:before {
  content: "\\e908";
}
.feather-anchor:before {
  content: "\\e909";
}
.feather-aperture:before {
  content: "\\e90a";
}
.feather-archive:before {
  content: "\\e90b";
}
.feather-arrow-down:before {
  content: "\\e90c";
}
.feather-arrow-down-circle:before {
  content: "\\e90d";
}
.feather-arrow-down-left:before {
  content: "\\e90e";
}
.feather-arrow-down-right:before {
  content: "\\e90f";
}
.feather-arrow-left:before {
  content: "\\e910";
}
.feather-arrow-left-circle:before {
  content: "\\e911";
}
.feather-arrow-right:before {
  content: "\\e912";
}
.feather-arrow-right-circle:before {
  content: "\\e913";
}
.feather-arrow-up:before {
  content: "\\e914";
}
.feather-arrow-up-circle:before {
  content: "\\e915";
}
.feather-arrow-up-left:before {
  content: "\\e916";
}
.feather-arrow-up-right:before {
  content: "\\e917";
}
.feather-at-sign:before {
  content: "\\e918";
}
.feather-award:before {
  content: "\\e919";
}
.feather-bar-chart:before {
  content: "\\e91a";
}
.feather-bar-chart-2:before {
  content: "\\e91b";
}
.feather-battery:before {
  content: "\\e91c";
}
.feather-battery-charging:before {
  content: "\\e91d";
}
.feather-bell:before {
  content: "\\e91e";
}
.feather-bell-off:before {
  content: "\\e91f";
}
.feather-bluetooth:before {
  content: "\\e920";
}
.feather-bold:before {
  content: "\\e921";
}
.feather-book:before {
  content: "\\e922";
}
.feather-book-open:before {
  content: "\\e923";
}
.feather-bookmark:before {
  content: "\\e924";
}
.feather-box:before {
  content: "\\e925";
}
.feather-briefcase:before {
  content: "\\e926";
}
.feather-calendar:before {
  content: "\\e927";
}
.feather-camera:before {
  content: "\\e928";
}
.feather-camera-off:before {
  content: "\\e929";
}
.feather-cast:before {
  content: "\\e92a";
}
.feather-check:before {
  content: "\\e92b";
}
.feather-check-circle:before {
  content: "\\e92c";
}
.feather-check-square:before {
  content: "\\e92d";
}
.feather-chevron-down:before {
  content: "\\e92e";
}
.feather-chevron-left:before {
  content: "\\e92f";
}
.feather-chevron-right:before {
  content: "\\e930";
}
.feather-chevron-up:before {
  content: "\\e931";
}
.feather-chevrons-down:before {
  content: "\\e932";
}
.feather-chevrons-left:before {
  content: "\\e933";
}
.feather-chevrons-right:before {
  content: "\\e934";
}
.feather-chevrons-up:before {
  content: "\\e935";
}
.feather-chrome:before {
  content: "\\e936";
}
.feather-circle:before {
  content: "\\e937";
}
.feather-clipboard:before {
  content: "\\e938";
}
.feather-clock:before {
  content: "\\e939";
}
.feather-cloud:before {
  content: "\\e93a";
}
.feather-cloud-drizzle:before {
  content: "\\e93b";
}
.feather-cloud-lightning:before {
  content: "\\e93c";
}
.feather-cloud-off:before {
  content: "\\e93d";
}
.feather-cloud-rain:before {
  content: "\\e93e";
}
.feather-cloud-snow:before {
  content: "\\e93f";
}
.feather-code:before {
  content: "\\e940";
}
.feather-codepen:before {
  content: "\\e941";
}
.feather-codesandbox:before {
  content: "\\e942";
}
.feather-coffee:before {
  content: "\\e943";
}
.feather-columns:before {
  content: "\\e944";
}
.feather-command:before {
  content: "\\e945";
}
.feather-compass:before {
  content: "\\e946";
}
.feather-copy:before {
  content: "\\e947";
}
.feather-corner-down-left:before {
  content: "\\e948";
}
.feather-corner-down-right:before {
  content: "\\e949";
}
.feather-corner-left-down:before {
  content: "\\e94a";
}
.feather-corner-left-up:before {
  content: "\\e94b";
}
.feather-corner-right-down:before {
  content: "\\e94c";
}
.feather-corner-right-up:before {
  content: "\\e94d";
}
.feather-corner-up-left:before {
  content: "\\e94e";
}
.feather-corner-up-right:before {
  content: "\\e94f";
}
.feather-cpu:before {
  content: "\\e950";
}
.feather-credit-card:before {
  content: "\\e951";
}
.feather-crop:before {
  content: "\\e952";
}
.feather-crosshair:before {
  content: "\\e953";
}
.feather-database:before {
  content: "\\e954";
}
.feather-delete:before {
  content: "\\e955";
}
.feather-disc:before {
  content: "\\e956";
}
.feather-dollar-sign:before {
  content: "\\e957";
}
.feather-download:before {
  content: "\\e958";
}
.feather-download-cloud:before {
  content: "\\e959";
}
.feather-droplet:before {
  content: "\\e95a";
}
.feather-edit:before {
  content: "\\e95b";
}
.feather-edit-2:before {
  content: "\\e95c";
}
.feather-edit-3:before {
  content: "\\e95d";
}
.feather-external-link:before {
  content: "\\e95e";
}
.feather-eye:before {
  content: "\\e95f";
}
.feather-eye-off:before {
  content: "\\e960";
}
.feather-facebook:before {
  content: "\\e961";
}
.feather-fast-forward:before {
  content: "\\e962";
}
.feather-feather:before {
  content: "\\e963";
}
.feather-figma:before {
  content: "\\e964";
}
.feather-file:before {
  content: "\\e965";
}
.feather-file-minus:before {
  content: "\\e966";
}
.feather-file-plus:before {
  content: "\\e967";
}
.feather-file-text:before {
  content: "\\e968";
}
.feather-film:before {
  content: "\\e969";
}
.feather-filter:before {
  content: "\\e96a";
}
.feather-flag:before {
  content: "\\e96b";
}
.feather-folder:before {
  content: "\\e96c";
}
.feather-folder-minus:before {
  content: "\\e96d";
}
.feather-folder-plus:before {
  content: "\\e96e";
}
.feather-framer:before {
  content: "\\e96f";
}
.feather-frown:before {
  content: "\\e970";
}
.feather-gift:before {
  content: "\\e971";
}
.feather-git-branch:before {
  content: "\\e972";
}
.feather-git-commit:before {
  content: "\\e973";
}
.feather-git-merge:before {
  content: "\\e974";
}
.feather-git-pull-request:before {
  content: "\\e975";
}
.feather-github:before {
  content: "\\e976";
}
.feather-gitlab:before {
  content: "\\e977";
}
.feather-globe:before {
  content: "\\e978";
}
.feather-grid:before {
  content: "\\e979";
}
.feather-hard-drive:before {
  content: "\\e97a";
}
.feather-hash:before {
  content: "\\e97b";
}
.feather-headphones:before {
  content: "\\e97c";
}
.feather-heart:before {
  content: "\\e97d";
}
.feather-help-circle:before {
  content: "\\e97e";
}
.feather-hexagon:before {
  content: "\\e97f";
}
.feather-home:before {
  content: "\\e980";
}
.feather-image:before {
  content: "\\e981";
}
.feather-inbox:before {
  content: "\\e982";
}
.feather-info:before {
  content: "\\e983";
}
.feather-instagram:before {
  content: "\\e984";
}
.feather-italic:before {
  content: "\\e985";
}
.feather-key:before {
  content: "\\e986";
}
.feather-layers:before {
  content: "\\e987";
}
.feather-layout:before {
  content: "\\e988";
}
.feather-life-buoy:before {
  content: "\\e989";
}
.feather-link:before {
  content: "\\e98a";
}
.feather-link-2:before {
  content: "\\e98b";
}
.feather-linkedin:before {
  content: "\\e98c";
}
.feather-list:before {
  content: "\\e98d";
}
.feather-loader:before {
  content: "\\e98e";
}
.feather-lock:before {
  content: "\\e98f";
}
.feather-log-in:before {
  content: "\\e990";
}
.feather-log-out:before {
  content: "\\e991";
}
.feather-mail:before {
  content: "\\e992";
}
.feather-map:before {
  content: "\\e993";
}
.feather-map-pin:before {
  content: "\\e994";
}
.feather-maximize:before {
  content: "\\e995";
}
.feather-maximize-2:before {
  content: "\\e996";
}
.feather-meh:before {
  content: "\\e997";
}
.feather-menu:before {
  content: "\\e998";
}
.feather-message-circle:before {
  content: "\\e999";
}
.feather-message-square:before {
  content: "\\e99a";
}
.feather-mic:before {
  content: "\\e99b";
}
.feather-mic-off:before {
  content: "\\e99c";
}
.feather-minimize:before {
  content: "\\e99d";
}
.feather-minimize-2:before {
  content: "\\e99e";
}
.feather-minus:before {
  content: "\\e99f";
}
.feather-minus-circle:before {
  content: "\\e9a0";
}
.feather-minus-square:before {
  content: "\\e9a1";
}
.feather-monitor:before {
  content: "\\e9a2";
}
.feather-moon:before {
  content: "\\e9a3";
}
.feather-more-horizontal:before {
  content: "\\e9a4";
}
.feather-more-vertical:before {
  content: "\\e9a5";
}
.feather-mouse-pointer:before {
  content: "\\e9a6";
}
.feather-move:before {
  content: "\\e9a7";
}
.feather-music:before {
  content: "\\e9a8";
}
.feather-navigation:before {
  content: "\\e9a9";
}
.feather-navigation-2:before {
  content: "\\e9aa";
}
.feather-octagon:before {
  content: "\\e9ab";
}
.feather-package:before {
  content: "\\e9ac";
}
.feather-paperclip:before {
  content: "\\e9ad";
}
.feather-pause:before {
  content: "\\e9ae";
}
.feather-pause-circle:before {
  content: "\\e9af";
}
.feather-pen-tool:before {
  content: "\\e9b0";
}
.feather-percent:before {
  content: "\\e9b1";
}
.feather-phone:before {
  content: "\\e9b2";
}
.feather-phone-call:before {
  content: "\\e9b3";
}
.feather-phone-forwarded:before {
  content: "\\e9b4";
}
.feather-phone-incoming:before {
  content: "\\e9b5";
}
.feather-phone-missed:before {
  content: "\\e9b6";
}
.feather-phone-off:before {
  content: "\\e9b7";
}
.feather-phone-outgoing:before {
  content: "\\e9b8";
}
.feather-pie-chart:before {
  content: "\\e9b9";
}
.feather-play:before {
  content: "\\e9ba";
}
.feather-play-circle:before {
  content: "\\e9bb";
}
.feather-plus:before {
  content: "\\e9bc";
}
.feather-plus-circle:before {
  content: "\\e9bd";
}
.feather-plus-square:before {
  content: "\\e9be";
}
.feather-pocket:before {
  content: "\\e9bf";
}
.feather-power:before {
  content: "\\e9c0";
}
.feather-printer:before {
  content: "\\e9c1";
}
.feather-radio:before {
  content: "\\e9c2";
}
.feather-refresh-ccw:before {
  content: "\\e9c3";
}
.feather-refresh-cw:before {
  content: "\\e9c4";
}
.feather-repeat:before {
  content: "\\e9c5";
}
.feather-rewind:before {
  content: "\\e9c6";
}
.feather-rotate-ccw:before {
  content: "\\e9c7";
}
.feather-rotate-cw:before {
  content: "\\e9c8";
}
.feather-rss:before {
  content: "\\e9c9";
}
.feather-save:before {
  content: "\\e9ca";
}
.feather-scissors:before {
  content: "\\e9cb";
}
.feather-search:before {
  content: "\\e9cc";
}
.feather-send:before {
  content: "\\e9cd";
}
.feather-server:before {
  content: "\\e9ce";
}
.feather-settings:before {
  content: "\\e9cf";
}
.feather-share:before {
  content: "\\e9d0";
}
.feather-share-2:before {
  content: "\\e9d1";
}
.feather-shield:before {
  content: "\\e9d2";
}
.feather-shield-off:before {
  content: "\\e9d3";
}
.feather-shopping-bag:before {
  content: "\\e9d4";
}
.feather-shopping-cart:before {
  content: "\\e9d5";
}
.feather-shuffle:before {
  content: "\\e9d6";
}
.feather-sidebar:before {
  content: "\\e9d7";
}
.feather-skip-back:before {
  content: "\\e9d8";
}
.feather-skip-forward:before {
  content: "\\e9d9";
}
.feather-slack:before {
  content: "\\e9da";
}
.feather-slash:before {
  content: "\\e9db";
}
.feather-sliders:before {
  content: "\\e9dc";
}
.feather-smartphone:before {
  content: "\\e9dd";
}
.feather-smile:before {
  content: "\\e9de";
}
.feather-speaker:before {
  content: "\\e9df";
}
.feather-square:before {
  content: "\\e9e0";
}
.feather-star:before {
  content: "\\e9e1";
}
.feather-stop-circle:before {
  content: "\\e9e2";
}
.feather-sun:before {
  content: "\\e9e3";
}
.feather-sunrise:before {
  content: "\\e9e4";
}
.feather-sunset:before {
  content: "\\e9e5";
}
.feather-tablet:before {
  content: "\\e9e6";
}
.feather-tag:before {
  content: "\\e9e7";
}
.feather-target:before {
  content: "\\e9e8";
}
.feather-terminal:before {
  content: "\\e9e9";
}
.feather-thermometer:before {
  content: "\\e9ea";
}
.feather-thumbs-down:before {
  content: "\\e9eb";
}
.feather-thumbs-up:before {
  content: "\\e9ec";
}
.feather-toggle-left:before {
  content: "\\e9ed";
}
.feather-toggle-right:before {
  content: "\\e9ee";
}
.feather-tool:before {
  content: "\\e9ef";
}
.feather-trash:before {
  content: "\\e9f0";
}
.feather-trash-2:before {
  content: "\\e9f1";
}
.feather-trello:before {
  content: "\\e9f2";
}
.feather-trending-down:before {
  content: "\\e9f3";
}
.feather-trending-up:before {
  content: "\\e9f4";
}
.feather-triangle:before {
  content: "\\e9f5";
}
.feather-truck:before {
  content: "\\e9f6";
}
.feather-tv:before {
  content: "\\e9f7";
}
.feather-twitch:before {
  content: "\\e9f8";
}
.feather-twitter:before {
  content: "\\e9f9";
}
.feather-type:before {
  content: "\\e9fa";
}
.feather-umbrella:before {
  content: "\\e9fb";
}
.feather-underline:before {
  content: "\\e9fc";
}
.feather-unlock:before {
  content: "\\e9fd";
}
.feather-upload:before {
  content: "\\e9fe";
}
.feather-upload-cloud:before {
  content: "\\e9ff";
}
.feather-user:before {
  content: "\\ea00";
}
.feather-user-check:before {
  content: "\\ea01";
}
.feather-user-minus:before {
  content: "\\ea02";
}
.feather-user-plus:before {
  content: "\\ea03";
}
.feather-user-x:before {
  content: "\\ea04";
}
.feather-users:before {
  content: "\\ea05";
}
.feather-video:before {
  content: "\\ea06";
}
.feather-video-off:before {
  content: "\\ea07";
}
.feather-voicemail:before {
  content: "\\ea08";
}
.feather-volume:before {
  content: "\\ea09";
}
.feather-volume-1:before {
  content: "\\ea0a";
}
.feather-volume-2:before {
  content: "\\ea0b";
}
.feather-volume-x:before {
  content: "\\ea0c";
}
.feather-watch:before {
  content: "\\ea0d";
}
.feather-wifi:before {
  content: "\\ea0e";
}
.feather-wifi-off:before {
  content: "\\ea0f";
}
.feather-wind:before {
  content: "\\ea10";
}
.feather-x:before {
  content: "\\ea11";
}
.feather-x-circle:before {
  content: "\\ea12";
}
.feather-x-octagon:before {
  content: "\\ea13";
}
.feather-x-square:before {
  content: "\\ea14";
}
.feather-youtube:before {
  content: "\\ea15";
}
.feather-zap:before {
  content: "\\ea16";
}
.feather-zap-off:before {
  content: "\\ea17";
}
.feather-zoom-in:before {
  content: "\\ea18";
}
.feather-zoom-out:before {
  content: "\\ea19";
}

`;

export default AppLayout;
