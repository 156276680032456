import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { default as config } from '../../../../config/channels';
import useSiteContext from '../../../../contexts/site/useSiteContext';
import FormBuilder, { FormData } from './FormBuilder';

const CustomerForm = () => {
    const siteContext = useSiteContext();
    const [formData, setFormData] = useState<FormData>({});

    useEffect(() => {
        if (siteContext.channel === config.collection.name) {
            setFormData({
                firstName: true,
                lastName: true,
                cardholderName: true,
                email: true,
                phone: true,
                opt_in_marketing: true,
            });
        }

        // time_select removed ^ and delivery
        if (siteContext.channel === config.delivery.name) {
            setFormData({
                firstName: true,
                lastName: true,
                cardholderName: true,
                email: true,
                phone: true,
                address: true,
                opt_in_marketing: true,
            });
        }
        if (siteContext.channel === config.dineIn.name) {
            setFormData({
                firstName: true,
                lastName: true,
                cardholderName: true,
                email: true,
                table_select: true,
                opt_in_marketing: true,
            });
        }
    }, [siteContext]);

    return (
        <StyledContainer>
            <FormBuilder form={formData} />
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    background-color: #FFFFFF;
`;

export default CustomerForm;
