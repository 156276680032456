import axios from 'axios';
import toaster from "toasted-notes";

const API_URL_FIND = 'https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws';
const API_URL_RETRIEVE = 'https://api.addressy.com/Capture/Interactive/Retrieve/v1.20/json3.ws';

const loquate = axios.create({
    headers: { 'Content-Type': 'application/json' },
    withCredentials: false,
});

// loquate.interceptors.request.use(function (config) {
//     toaster.closeAll();
//     return config;
// }, function (error) {
//     return Promise.reject(error);
// });

export const findLoquateData = async ( query: string, container?: string|null ) => {
    const params = new URLSearchParams({
        key: 'KD49-PU22-ZK59-XX63',
        //bias: 'true',
        countries: 'GB'
    });

    params.append('Text', query);

    if ( container ) {
        params.append('Container', container);
    }

    return loquate.get(API_URL_FIND + '?' + params.toString());
};

export const retrieveLoquateData = async ( id: string ) => {
    const params = new URLSearchParams({
        key: 'KD49-PU22-ZK59-XX63',
        //bias: 'true',
        countries: 'GB'
    });

    params.append('Id', id);

    return loquate.get(API_URL_RETRIEVE + '?' + params.toString());
};