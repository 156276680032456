import { createContext } from 'react';
import { APIError } from '../../services/api';
import { TChannel } from '../operator/operator-context';

/**************************
 *
 * Sites
 *
 **************************/

export interface Sites {
    data: TSite[];
    errors: APIError;
    event_id: number;
    flow: string;
}

export interface TSite {
    uid: string;
    handle: string;
    name: string;
    lat: number;
    lng: number;
    timezone: string;
    email: string;
    phone: string;
    address_1: string;
    address_2: null;
    address_city: string;
    address_postcode: string;
    address_country: string;
    channels: Channels;
    is_open: boolean;
    opening_times: string;
    opening_hours: OpeningHours[];
    sites_count: number;
    distance?: number;
}

export interface Channels {
    collection?: Collection;
    dine_in?: DineIn;
    delivery?: Delivery;
}

export interface Collection {
    is_open: boolean;
    opening_times?: string;
    opening_hours: OpeningHours[];
}

export interface Delivery {
    is_open: boolean;
    opening_hours: OpeningHours[];
}

export interface DineIn {
    is_open: boolean;
    opening_hours: OpeningHours[];
    opening_times: string;
    table_numbers: string[];
    allow_tabs: boolean;
}

export interface OpeningHours {
    day: string;
    time: string;
}

/**************************
 *
 * Menu
 *
 **************************/

export interface Menu {
    data: MenuData;
    errors: APIError;
    event_id: number;
    flow: string;
}

export interface MenuData {
    site: TSite;
    timeslots: Timeslot[];
    menu: TMenu;
    flow?: string;
}

export interface TMenu {
    uid: string;
    name: string;
    categories: Category[];
}

export interface Category {
    uid: string;
    name: string;
    description: string;
    is_hidden: boolean;
    items: Item[];
}

export interface Item {
    uid: string;
    name: string;
    description: string;
    price: number;
    is_hidden: boolean;
    is_new: boolean;
    is_vegetarian: boolean;
    is_halal: boolean;
    is_gluten_free: boolean;
    is_special: boolean;
    is_vegan: boolean;
    allergens: Allergens;
    image: string;
    modifier_groups: ModifierGroup[];
    quantity?: number;
    modifiers?: ModifierShort[];
    selected_modifiers?: ModifierShort[];
    unselected_default_modifiers?: ModifierShort[];
    modifiersTotal: number;
    item_total: number;
    calories?: string;
    basketIndex?: string;
}

export enum Allergens {
    Dairy = 'Dairy',
    Empty = '',
}

export interface ModifierGroup {
    uid: string;
    name: string;
    subtitle: string;
    min: number;
    max: number;

    multi_choice: boolean;
    default_subtitle: boolean;

    modifiers: Modifier[];
}

export interface Modifier {
    uid: string;
    name: string;
    price: number;
    is_hidden: boolean;
    is_default: boolean;
    is_new: boolean;
    is_vegetarian: boolean;
    is_gluten_free: boolean;
    is_special: boolean;
    is_vegan: boolean;
    quantity: number;
    group_uid?: string;
    calories?: string;
}

export interface ModifierShort {
    uid: string;
    name: string;
    quantity: number;
    group_uid: string;
    display?: boolean;
}

export interface TSite {
    min_order?: number;
    max_order?: number;
    banner?: string;
    instructions?: null;
    show_instructions_at_checkout?: boolean;
    show_instructions_at_confirm?: boolean;
}

export interface Timeslot {
    is_asap: boolean;
    id: string;
    name: string;
}

export type SiteContextData = {
    site: TSite | null;
    fetchSite: (
        id: string,
        channel: TChannel,
        flow?: string,
        handle?: string,
        tableNumber?: string,
        postcode?: string,
    ) => void;

    sites: TSite[] | null;
    fetchSites: (handle: string, flow: string, postcode?: string) => void;

    channel: TChannel;
    setChannel: (channel: TChannel) => void;

    menu: TMenu | null;
    timeslots: Timeslot[] | null;
    setTimeslots: (timeslots: Timeslot[]) => void;
    fetchTimeslots: (siteHandle: string, flow: string) => void;

    loading: boolean;
    setLoading: (state: boolean) => void;

    flow: string;
    setFlow: (flow: string) => void;
};

export const SiteContext = createContext<SiteContextData>({
    site: null,
    fetchSite: () => {},

    sites: null,
    fetchSites: () => {},

    menu: null,
    timeslots: null,
    setTimeslots: () => {},
    fetchTimeslots: () => {},

    channel: 'collection',
    setChannel: () => {},

    loading: false,
    setLoading: () => {},

    flow: 'noflow',
    setFlow: () => {},
});
