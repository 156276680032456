import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import { ChannelConfigSettings } from '../../../../config/channels';
import { operatorName } from '../../../../config/operator';
import { TSite } from '../../../../contexts/site/site-context';
import { filterSites } from '../../../../services/api/site';
import Alert from '../../../general/Alert';
import SiteCard from './SiteCard';
import icon_search from '../../../../assets/images/search.svg';
import {useSearchParams} from "react-router-dom";

interface SiteFilterProps {
    sites: TSite[];
    channel: ChannelConfigSettings;
}

const SiteFilter = ({ sites = [], channel }: SiteFilterProps) => {
    const [filteredSites, setFilteredSites] = useState<TSite[]>(sites ?? []);
    const [mappedSites, setMappedSites] = useState<TSite[]>(sites ?? []);
    const [filter, setFilter] = useState('');
    const [locationLoading, setLocationLoading] = useState(false);
    const [searchParams] = useSearchParams();

    const postcodeSearchInput = searchParams.has('postcode') ? searchParams.get('postcode') : null;

    // Reset search on channel change
    useEffect(() => {
        setFilter('');
    }, [channel]);

    useEffect(() => {

        if (!postcodeSearchInput) {
            return;
        }

        let mapped: TSite[] = [];

        filterSites(operatorName, postcodeSearchInput).then((data: { uid: string, distance: number }[]) => {

            mapped = sites.map((s) => {
                return {
                    ...s,
                    distance: data.find((site) => site.uid === s.uid )?.distance
                }
            }).sort((a,b) => (a.distance ?? 1) - (b.distance ?? 1) );

            setMappedSites(mapped);
            setFilteredSites(mapped);
            setLocationLoading(false);
        });

    }, [ postcodeSearchInput ]);

    useEffect(() => {
        let filtered: TSite[] = [];
        if (filter.length > 2) {
            filterSites(operatorName, filter).then((data: {uid: string, distance?: number}[]) => {

                if ( data.length > 0 && data[0].hasOwnProperty('distance') ) {
                    filtered = sites.map((s) => {
                        return {
                            ...s,
                            distance: data.find((site) => site.uid === s.uid )?.distance
                        }
                    }).sort((a,b) => (a.distance ?? 1) - (b.distance ?? 1) );

                } else {
                    filtered = sites.filter((site) => {
                        // Search by name
                        // console.log(data.indexOf(site.uid), site.uid, data)
                        return data.findIndex(s => s.uid === site.uid) !== -1;
                    });
                }

                setFilteredSites(filtered);
            });
        } else {
            filtered = sites;

            if ( postcodeSearchInput ) {
                filtered = mappedSites;
            }

            setFilteredSites(filtered);
        }
    }, [filter, postcodeSearchInput]);

    return (
        <StyledForm>
            <InputWrapper input={filter}>
                <ClearFormBtn
                    className={ `${ filter.length > 0 ? 'with-input':'no-input' } `}
                    onClick={() => setFilter('')}>
                    <i className="feather feather-x feather-24"/>
                </ClearFormBtn>
                <Form.Control
                    size="lg"
                    type="text"
                    className=""
                    placeholder={t`Search by city, street or postcode`}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
            </InputWrapper>

            <div className="wrap-sites">
                {filteredSites.length > 0 ? (
                    filteredSites.map((site) => (
                        <SiteCard
                            site={site}
                            channel={channel}
                            key={`${channel.name}_${site.uid}`}
                        />
                    ))
                ) : (
                    <Alert icon={'info'} iconColor="invalid" className="invalid-alert">
                        <p className="mb-0">{t`Sorry, we couldn't find the location you are looking for. Try searching with location name or post code.`}</p>
                    </Alert>
                )}
            </div>
        </StyledForm>
    );
};

const StyledForm = styled(Form.Group)`
    .invalid-alert {
        margin: 0 8px;
    }
    .wrap-sites {
        margin-top: 8px;
    }

`;

const ClearFormBtn = styled.button`
    border: 0;
    background-color: transparent;
    position: absolute;
    right: 16px;
    top: 10px;

    color: var(--text-grey);
    font-size: 16px;
    line-height: 24px;

    align-items: center;

    img { margin-left: 8px; }

    &.with-input {display: flex; }
    &.no-input { display: none; }
`;
const InputWrapper = styled.div<{ input: string }>`
    position: relative;

    .form-control {
        background-image: url('${icon_search}');
        font-size: 16px;
        line-height: 24px;
        background-repeat: no-repeat;
        background-position: 16px 50%;
        padding: 16px 16px 16px 48px;
        height: 48px;
        text-overflow: ellipsis;
        ${(props) => (props.input.length > 0 ? `padding-right: 48px;` : null)}

        @media screen and (max-width: 768px) {
            background-position: 17px 12px, right 19px center;
        }
    }

    .location_icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;
        cursor: pointer;
    }

    .spinner-grow {
        height: 18px;
        width: 18px;
    }
`;

export default SiteFilter;
