import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';
import vocab from '../../../config/channels';
import { operatorName } from '../../../config/operator';
import { TChannel } from '../../../contexts/operator/operator-context';
import useOperatorContext from '../../../contexts/operator/useOperatorContext';
import { TSite } from '../../../contexts/site/site-context';
import useSiteContext from '../../../contexts/site/useSiteContext';
import PageSpinnerThree from '../../general/PageSpinnerThree';
import AppLayout from '../../layouts/app/AppLayout';
import useBasketContext from "../../../contexts/basket/useBasketContext";
import {iLog} from "../../../index";
import mixpanel from 'mixpanel-browser';
import {Form, Nav} from "react-bootstrap";
import {CTA} from "../../general/Button";
import config from "../../../config/channels";
import powered_by from "../../../assets/images/logo-powered-by.svg";


const TableSelectPage = () => {
    const operatorContext = useOperatorContext();
    const siteContext = useSiteContext();
    const basketContext = useBasketContext();
    const navigate = useNavigate();

    const [tableNumber, setTableNumber] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [site, setSite] = useState<TSite|undefined>(undefined);

    const { restaurant_id } = useParams();


    useEffect(() => {

        basketContext.clearBasket();
        basketContext.setTableNumber(null);
        localStorage.removeItem('postcode');

        if (siteContext) {
            let flow = siteContext.flow === '' ? '' : (operatorContext.operator?.flow ?? 'noflow') ;
            siteContext.fetchSites(operatorName, flow);
        } else {
            console.warn('Skipped fetch site call.');
        }
    }, []);

    useEffect(() => {

        if ( siteContext.sites ) {

            let found_site = siteContext.sites?.find(
                (site)=> site.handle === restaurant_id);

            if ( found_site ) {
                setSite(found_site);
            } else {
                navigate(`/${config.dineIn.handle}`);
            }

        }

    }, [siteContext.sites])


    const handleTableStart = () => {
        basketContext.setTableNumber(tableNumber);
        navigate(`/${config.dineIn.handle}/${site?.handle}`);
    }

    const handleDiffLocation = () => {
        navigate(`/${config.dineIn.handle}`);
    }


    if (!siteContext || !siteContext.sites) {
        return <PageSpinnerThree show={true} />;
    }

    return (
        <AppLayout page="order-at-table">
            <Helmet>
                <title>
                    {operatorContext.operator?.name ?? ''} {t`Online Ordering - Powered by orderswift`}
                </title>
                <link rel="icon" href={operatorContext.operator?.theme?.favicon_url} />
            </Helmet>


            <ChannelSelectWrapper>
                <StyledContainer className={'channel-select-container'}>
                    <StyledNavBrand onClick={() => navigate('/')} className="logoClass" />

                    {/*<Heading className="text-left">Order At<br />Table</Heading>*/}
                    <Heading className="text-left"
                             dangerouslySetInnerHTML={{ __html: operatorContext?.operator?.theme.v3.language.slogan ?? '' }} />
                    <Location>
                        <i className="feather feather-map-pin feather-24" />
                        <span>{ site?.name }</span>
                    </Location>

                    <ChannelContent>
                        <span className='d-md-none mobile_bg' />
                        <div className='channelWrapper'>
                            <StyledForm>
                                <InputWrapper controlId={'formSearch'}>
                                    <Form.Label>Where are you sitting?</Form.Label>
                                    <Form.Control
                                        size="lg"
                                        type="text"
                                        className="table-input"
                                        placeholder={t`Enter table number`}
                                        value={tableNumber}
                                        onChange={(e) => setTableNumber(e.target.value)}
                                        autoFocus
                                    />
                                    <CTA block
                                         disabled={!tableNumber || loading}
                                         onClick={handleTableStart} >
                                        <span>{t`View menu`}</span>
                                    </CTA>
                                </InputWrapper>
                            </StyledForm>
                            { siteContext.sites.length > 1 ? (
                                <OtherLocation
                                    onClick={handleDiffLocation}>
                                    I'm in another restaurant
                                </OtherLocation>
                            ) : null }
                        </div>
                    </ChannelContent>
                </StyledContainer>

                <div className='orderswift-banner-channel'>
                    <img width={188} height={28} src={powered_by} alt={'Powered by Orderswift'} />
                </div>

            </ChannelSelectWrapper>


            <Outlet />
        </AppLayout>
    );
};

const OtherLocation = styled.div`
    color: var(--brand);
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
`;

const Location = styled.div`
    font-weight: semi-bold;
    display: flex;
    gap: 8px;
    align-items: center;
    font-family: var(--text_ui_font);
    font-weight: var(--text_ui_weight);
    font-size: var(--text_ui_cta);
    text-transform: var(--text_ui_case);
    color: var(--brand);
    margin-bottom: 24px;
    @media screen and (max-width: 767px) {
        margin-bottom: 20px;
    }
`;

const StyledForm = styled(Form.Group)`
    padding-bottom: 16px;
`;

const InputWrapper = styled(Form.Group)`
    position: relative;

    .site-filter-input {
        height: 50px;
        &::placeholder { color:var(--placeholder); }
    }
    
    .form-label {
        font-size: 16px;
        line-height: 16px;
        color: var(--text-black);
        margin-bottom: 12px;
    }


    i.search-icon {
        position: absolute;
        top: calc(24px - 12px); /* 24 is half of height 48) */
        left: 12px;
    }
    i.find-icon {
        position: absolute;
        top: calc(24px - 12px); /* 24 is half of height 48) */
        right: 12px;
        &.with-input { display: none; }
        &:hover { cursor: pointer; }
    }

    .form-control {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        padding: 16px 48px 16px 16px;
        height: 48px;
        margin-bottom: 20px;

        padding-right: 30px;

        @media screen and (max-width: 768px) {
            background-position: 17px 12px, right 19px center;
        }
    }

    .location_icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;
        cursor: pointer;
    }

    .spinner-grow {
        height: 18px;
        width: 18px;
    }
`;

const ChannelContent = styled.div`
    flex-grow: 1;
    margin-top: 20px; 
`;

const ChannelSelectWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: calc(48px + 65px) 48px 48px 48px;
    @media screen and (max-width: 767px){
        width: 100%;
        padding: calc(32px + 65px) 20px 48px 20px;
        margin-top: 0;
    }
    
    @media screen and (min-width: 768px){
        height: 100vh;
        // offset bottom (56px nav + 24px existing padding = 80px)
    }
    @media screen and (min-width: 2000px) {
        display: block;
    }
    
    .orderswift-banner-channel {
        z-index: 999;
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        left: 0;
        background: white;
        box-shadow: 0 -1px 0 var(--border-grey);
        padding: 2px 0;
    }
    
`;

const StyledContainer = styled.div`  
    width: 100%;
    max-width: 600px;
    @media screen and (max-width: 767px){
        width: 100%;
    }
`;

const StyledNav = styled(({ soloChannel, ...props }) => <Nav {...props} />)`
    padding: 0;
    top: 0;
    z-index: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    
    .nav-item {
        &:nth-child(2) {
            margin-left: 0px;
            margin-right: -1px;
        }
    }

    &.nav-lines {
        border-bottom: 1px solid transparent;

        padding-bottom: 0;

        @media screen and (max-width: 767px) {

            justify-content: start;
            display: flex;
            gap: 24px;

        }
        
        @media screen and (max-width: 452px) {
            gap: 6px;
        }
    }

    ${(props) =>
    props.soloChannel
        ? null
        : `@media screen and (max-width: 392px) {
            height: 51px;
            overflow-x: auto;
            overflow-y: hidden;
            display: block;
            white-space: nowrap;
        }`}

    @media screen and (max-width: 767px) {
        position: relative !important;
        z-index: 1 !important;
        justify-content: space-between;
        display: flex;
        
    }



`;

const Heading = styled.h1`
    margin-bottom: 24px;
    @media screen and (max-width: 767px) {
        margin-top: 32px;
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 32px;
    }
`;

const StyledNavBrand = styled.div`
    background-image:  url(${(props) => props.theme.logo_url});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: middle;
    height: 48px;
    margin: 0;
    background-position: left;
    width: 290px;
    max-width: 85vw;
    display: block;
    cursor: pointer;
    margin-bottom: 134px;
    
    display: none;
`;

export default TableSelectPage;
